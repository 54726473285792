import React, { useEffect, useState } from "react";
import { getContact } from 'services/api/Shop';

import './Contact.scss';

const Contact = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");

  useEffect(() => {
    getContact().then((data) => {
      setEmail(data.content.email);
      setPhone(data.content.phone);
      setFax(data.content.fax);
      setAddress1(data.content.address1);
      setAddress2(data.content.address2);
      setCity(data.content.city);
      setZipCode(data.content.zipCode);
    });
  }, []);

  const url ="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1456.025662587726!2d6.094450658292751!3d43.12444799478576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c922595cd763e5%3A0xca005237cd5ec7d0!2sSica%20March%C3%A9%20Aux%20Fleurs%20d'Hy%C3%A8res!5e0!3m2!1sfr!2sfr!4v1584117925024!5m2!1sfr!2sfr";

  return (
    <div className="container contact">
      <h1>Contact</h1>
      <div className="row">
        <div className="col-md-6">
          <div className="contact-paragraph">
            <h2>Pour nous contacter :</h2>
            <div className="row">
              <div className="col-6">Par email :</div>
              <div className="col-6">
                <a href = {`mailto:${email}`}>{email}</a>
              </div>
            </div>
            <div className="row">
              <div className="col-6">Par téléphone :</div>
              <div className="col-6">
                <a href = {`tel:+33${phone.substring(1).replace(/\s/g, '')}`}>{phone}</a>
              </div>
            </div>
            <div className="row">
              <div className="col-6">Par Fax :</div>
              <div className="col-6">{fax}</div>
            </div>
          </div>
          <div className="contact-paragraph">
            <h2>Pour venir nous voir :</h2>
            <div className="row">
              <div className="col-3">Adresse :</div>
              <div className="col-9">
                <div>{address1}</div>
                <div>{address2}</div>
                <div>{zipCode} {city}</div>
              </div>
            </div>
          </div>
          
        </div>
        <div className="col-md-6">
          <iframe src={url} width="100%" height="100%" title="Carte google localisation centrale dia fleur" />
        </div>
      </div>
      
    </div>
  )
}

export default Contact;