import { DOMAIN, BASE_URL, PROTOCOL, buildHeader } from "./Config";
import * as Sentry from "@sentry/browser";

const buildData = (login, password, service, token = null) => {
  return {
    "session":{
      "url": BASE_URL,
      "orderDateTime": null,
      "token": token || ""
    },
    "content":{
      "login": login,
      "password": password,
      "submitButton":"Connexion",
      "domainName": DOMAIN,
      "token": token || "",
      "api":"customer",
      "service": service
    }
  };
}

const login = async (login, password) => {
  const data = buildData(login, password, "doLogin");

  const response = await fetch(PROTOCOL + BASE_URL + "/api/customer/doLogin", {
    method: "POST",
    headers: buildHeader(data, "doLogin"),
    body: JSON.stringify(data)
  })
  const jsonData = await response.json();
  return jsonData;
}

const resetPassword = async (email) => {
  
  return null;
}

const logout = async (login, token) => {
  try {
    const data = buildData(login, "", "doLogout", token);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/customer/doLogout", {
      method: "POST",
      headers: buildHeader(data, "doLogout"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const subscribe = async (infos) => {
  try {
    const data = buildData("", "", "validateSubscribe");
    Object.entries(infos).forEach(([key, value]) => {
      data.content[key] = value;
    });

    const response = await fetch(PROTOCOL + BASE_URL + "/api/customer/validateSubscribe", {
      method: "POST",
      headers: buildHeader(data, "validateSubscribe"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const accountDetails = async (token) => {
  try {
    const data = buildData("", "", "accountDetails", token);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/customer/accountDetails", {
      method: "POST",
      headers: buildHeader(data, "accountDetails"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const saveAccountDetails = async (token, dataAccount) => {
  try {
    const data = buildData("", "", "saveAccountDetails", token);
    data.content = dataAccount;

    const response = await fetch(PROTOCOL + BASE_URL + "/api/customer/saveAccountDetails", {
      method: "POST",
      headers: buildHeader(data, "saveAccountDetails"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const orderHistory = async (token, displayFrom, displayCount) => {
  try {
    const data = buildData("", "", "orderHistory", token);
    data.content.displayCount = displayCount;
    data.content.displayFrom = displayFrom;

    const response = await fetch(PROTOCOL + BASE_URL + "/api/customer/orderHistory", {
      method: "POST",
      headers: buildHeader(data, "orderHistory"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

export {
  login,
  logout,
  subscribe,
  accountDetails,
  saveAccountDetails,
  orderHistory,
  resetPassword
};
