import React, { useState, useEffect } from "react";
import { orderHistory } from "services/api/Customer";
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { getOrder } from 'services/api/Order';
import OrderDetail from "components/Modals/orderDetail/OrderDetail";
import "./Orders.scss"
import { DOMAIN, PROTOCOL } from "services/api/Config";

const Orders = ({ token }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [urlSuccess, setUrlSuccess] = useState("");
  const [urlFailure, setUrlFailure] = useState("");
  const [payment, setPayment] = useState(null);

  const updatePage = (event, page) => {
    setCurrentPage(page);
    loadOrders(null, page);
  }

  const loadOrders = (items = null, page = null) => {
    setIsLoading(true);
    const itemsToDisplay = items || itemPerPage;
    const pageToLoad = page || currentPage;
    const displayFrom = itemsToDisplay * (pageToLoad - 1);

    orderHistory(token, displayFrom, itemsToDisplay).then((data) => {
      setData(data.content.orders);
      let divider = Math.floor(data.content.count / itemsToDisplay);
      if (data.content.count % itemsToDisplay !== 0) divider += 1;
      setPayment(data.content.form);
      setPageCount(divider);
      setIsLoading(false);
    });
  }
  
  useEffect(() => {
    setUrlSuccess(`${window.location.origin}/products?successOrder=true`);
    setUrlFailure(`${window.location.origin}/products?successOrder=false`);
    loadOrders();
  }, [token]);

  const openModal = async (detail) => {
    setIsLoadingModal(true)
    setOpenDetail(true);
    const order = await getOrder(token, detail.id);
    setOrderDetail(order.content);
    setIsLoadingModal(false)
  }

  const openPdf = async (detail) => {
    window.open(`${PROTOCOL}${DOMAIN}/${detail.invoicePDF}`,"_blank")
  }

  const handleItemPerPage = (e) => {
    setItemPerPage(e.target.value);
    loadOrders(e.target.value);
  }

  return (
    <div className="orders">
      <div className="sub-header"></div>
      <div className="orders-title">
        <h3>Dernières commandes :</h3>

        <TextField
          id="itemsPerPage"
          select
          label="Commandes par page"
          defaultValue={10}
          value={itemPerPage}
          margin="dense"
          style={{minWidth: "120px"}}
          onChange={e => handleItemPerPage(e)}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </TextField>
      </div>
      {isLoading ? (
        <div className="orders-loading">
          <i className="fas fa-spinner fa-spin fa-lg"></i>
        </div>
      ) : (
        <>
          <List className="orders-container" sx={{ margin: '0 20px' }}>
            {data && data.map(d => (
              <div key={d.id}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={`${d.orderType} numéro: ${d.orderNumber}`}
                    secondary={
                      <React.Fragment>
                        <div className="orders-detailRow">
                          <div>
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.primary"
                              sx={{marginRight: '15px'}}
                            >
                              Livraison: {d.deliveryDateTimeDisplay}
                            </Typography>
                            <span className="orders-info">Etat: {d.statusName}</span>
                            <span className="orders-info">Montant: {d.totalAmount} €</span>
                            <br/>
                            <span className="orders-info">Facture numéro: {d.invoice.invoiceNumber}</span>
                            <span className="orders-info">{d.invoice.remain}</span>
                          </div>
                          
                          <div className="orders-detailIcon">
                            {d.fields &&(
                              <form action={payment?.action} method={payment?.method} acceptCharset={payment["accept-charset"]}>
                                {d.fields && d.invoice.remain !== "Réglée" &&
                                    Object.entries(d.fields)
                                    .filter(f => (f[0] !== "vads_url_success" && f[0] !== "vads_url_return"))
                                    .map((field, index) => (
                                      <input key={index} type="hidden" name={field[0]} value={field[1]} />
                                    ))}
                                <input type="hidden" name="vads_url_success" value={urlSuccess} />
                                <input type="hidden" name="vads_url_return" value={urlFailure} />
                                <Tooltip title={"Payer par CB"} placement="bottom">
                                  <Button className={d.invoice.remain === "Réglée" ? "disabled" : ""} type="submit" disabled={d.invoice.remain === "Réglée"}>
                                    <i className="fas fa-credit-card"></i>
                                  </Button>
                                </Tooltip>
                              </form>
                            )}
                            
                            <Tooltip title={"Voir le détail"} placement="bottom">
                              <Button type="button" onClick={() => openModal(d)}>
                                <i className="fas fa-file-alt"></i>
                              </Button>
                            </Tooltip>
                            {d.invoicePDF ? (
                              <Tooltip title={"Voir la facture au format PDF"} placement="bottom">
                                <Button type="button" onClick={() => openPdf(d)}>
                                  <i className="fas fa-file-pdf"></i>
                                </Button>
                              </Tooltip>) : ( '') 
                            }
                          </div>
                        </div>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            ))}
          </List>
          <div className="orders-pagination row g-4">
            <Pagination 
              count={pageCount} 
              color="primary" 
              style={{ display: "flex", justifyContent: "center" }}
              onChange={updatePage}
              page={currentPage}
            />
          </div>
        </>
      )}
      <OrderDetail openModal={openDetail} setOpenModal={setOpenDetail} order={orderDetail} isLoading={isLoadingModal} />
    </div>
  )
}

export default Orders;
