import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Password from "components/Fields/Password";

const Information = ({ data, saveAccount, setData }) => {
  const [reference, setReference] = useState("");
  const [shopName, setShopName] = useState("");
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (!data) return;
    setReference(data.code);
    setShopName(data.shopName);
    setFormData(data);
  }, [data]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleContactChange = (event) => {
    if (!event.target) {
      return;
    }

    const { id, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      ["contact"]: {
        ...((prevState && prevState["contact"]) || {}),
        [id]: value
      }
    }));
  };

  const handleCredentialsChange = (event) => {
    if (!event.target) {
      return;
    }

    const { id, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      ["credentials"]: {
        ...((prevState && prevState["credentials"]) || {}),
        [id]: value
      }
    }));
  };

  const handleSubmit = () => {
    setData(formData);
    saveAccount(formData)
  }

  return (
    <form className="container" >
      <div className="row mb-3">
        <div className="col-md-6">
          <span>Votre référence adhérent : {reference}</span><br />
          <span>Votre boutique : {shopName}</span>
          <TextField 
            fullWidth 
            id="email" 
            label="Email" 
            variant="outlined" 
            value={formData?.contact?.email}
            margin="dense"
            InputLabelProps={{ shrink: formData?.contact?.email !== "" }}
            onChange={e => handleContactChange(e)}/>          
          <Password 
            fieldName={"password"} 
            field={formData?.credentials?.password} 
            setField={handleCredentialsChange} 
            label={"Mot de passe"} 
            margin={"dense"}
          />
          <div className="container-civility">
            <div className="w-25">
              <TextField
                id="civ"
                select
                label="Civilité"
                defaultValue={""}
                value={formData?.contact?.civility}
                fullWidth
                InputLabelProps={{ shrink: formData?.contact?.civility }}
                onChange={e => handleContactChange(e)}
              >
                <MenuItem value={"1"}>M.</MenuItem>
                <MenuItem value={"2"}>Mme.</MenuItem>
              </TextField>
            </div>
            <TextField 
              fullWidth 
              id="lastName" 
              label="Nom" 
              variant="outlined"
              InputLabelProps={{ shrink: formData?.contact?.lastName  !== "" }}
              value={formData?.contact?.lastName}
              onChange={e => handleContactChange(e)}/>
          </div>
          <TextField 
            fullWidth 
            id="firstName" 
            label="Prénom" 
            variant="outlined" 
            InputLabelProps={{ shrink: formData?.contact?.firstName  !== "" }}
            value={formData?.contact?.firstName}
            margin="dense"
            onChange={e => handleContactChange(e)}/>
        </div>
        <div className="col-md-6" style={{marginTop: "48px"}}>
          <TextField 
            fullWidth 
            id="phone" 
            label="Téléphone" 
            variant="outlined" 
            InputLabelProps={{ shrink: formData?.contact?.phone  !== "" }}
            value={formData?.contact?.phone}
            margin="dense"
            onChange={e => handleContactChange(e)}/>
          <TextField 
            fullWidth 
            id="fax" 
            label="Fax" 
            variant="outlined" 
            InputLabelProps={{ shrink: formData?.contact?.fax  !== "" }}
            value={formData?.contact?.fax}
            margin="dense"
            onChange={e => handleContactChange(e)}/>
          <TextField 
            fullWidth 
            id="mobile" 
            label="Mobile" 
            variant="outlined" 
            InputLabelProps={{ shrink: formData?.contact?.mobile  !== "" }}
            value={formData?.contact?.mobile}
            margin="dense"
            onChange={e => handleContactChange(e)}/>
          <TextField
            id="newsletter"
            select
            label="Recevoir nos offres"
            defaultValue=""
            margin="dense"
            fullWidth
            InputLabelProps={{ shrink: formData?.contact?.newsletter !== "" }}
            onChange={e => handleChange(e)}
          >
            <MenuItem value={1}>Non</MenuItem>
            <MenuItem value={0}>Oui</MenuItem>
          </TextField>
        </div>
      </div>
      <div className="row d-flex">
        <div className="col-12">
          <FormControlLabel
            required
            control={<Checkbox checked={!!formData?.cgv} />}
            label="Accepter les C.G.V."
            labelPlacement="end"
            onChange={e => handleChange(e)}
          />
          <a href="/cgv" target="_blank"> Lire les CGV</a>
        </div>
      </div>
      <div className="row mb-4 mt-4 d-flex justify-content-end">
        <Button className="w-25" variant="contained" type="button" onClick={handleSubmit}>Mettre à jour</Button>
      </div>
    </form>
  )
}

export default Information;