import React, { useEffect, useState } from "react";
import { getPage } from 'services/api/Shop';

const Cgv = () => {
  const [data, setData] = useState(""); 

  useEffect(() => {
    getPage(136).then((data) => {
      setData(data?.content?.content)
    });
  }, []);

  return (
    <div className="container">
      <div dangerouslySetInnerHTML={{__html: data}} />
    </div>
  )
}

export default Cgv;