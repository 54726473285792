import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import "./Password.scss"

const Password = ({fieldName, field, label, setField, margin}) => {
    const [type, setType] = useState("password"); 

    return (
      <div className="field-password">
        <TextField 
          fullWidth 
          id={fieldName}
          type={type}
          label={label}
          margin={margin}
          variant="outlined" 
          value={field}
          required
          onChange={e => setField(e.target.value)} />

        {type === "password" ? (
          <i className="fas fa-eye" onClick={() => setType("text")}></i>
        ) : (
          <i className="fas fa-eye-slash" onClick={() => setType("password")}></i>
        )}
      </div>
    )
};

export default Password;