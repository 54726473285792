import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import { observer } from "mobx-react";
import Cart from "services/store/Cart";
import { useNavigate } from "react-router-dom";
import "./RightPanel.scss"
import CartItem from "components/CartItem/CartItem";
import Button from '@mui/material/Button';

const RightPanel = ({token, opened}) => {
  const navigate = useNavigate();
  const [canOrder, setCanOrder] = useState(false);
  const [comment, setComment] = useState("");
  
  const redirectToPayment = () => {
    if (!canOrder) return;

    navigate("/payment");
  }

  const setCartComment = (value) => {
    setComment(value);
    Cart.comment = value;
  }

  useEffect(() => {
    if (Cart.cart?.length > 0) {
      setCanOrder(true);
    } else {
      setCanOrder(false);
    }
  }, [Cart.cart?.length, token]);

  return (
    <div className={`right-panel ${opened ? "opened" : ""}`} id="menu-right">
      <p>Panier</p>
      <div className="cart-container">
        {Cart.cart.map((product, index) => (
          <CartItem product={product} token={token} key={index} />
        ))}
      </div>
      <p>Total commandé : {Cart.orderPrice} €</p>

      {Cart.paymentCard && (
        <div>
          <TextField 
              id="comment" 
              label="Commentaire" 
              value={comment}
              variant="outlined"
              size="small"
              multiline
              rows={3}
              onChange={e => setCartComment(e.target.value)} />
        </div>
      )}

      {Cart.canValidate ? (
        <Button 
          disabled={!canOrder} 
          style={{fontSize: '16px'}} 
          className="w-100" 
          variant="contained" 
          type="button" 
          onClick={redirectToPayment}>
            Commander
        </Button>
      ) : (
        <div className="cart-message">{Cart.cartMessage}</div>
      )}
      
    </div>
  )
}

export default observer(RightPanel);