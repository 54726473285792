import sha256 from 'crypto-js/sha256';

const BASE_URL = "api.lcdf.fr";
const PROTOCOL = "https://";
const DOMAIN = "www.lcdf.fr";
const TIMEOUT_LOGOUT = 900;

const COUNTRIES = [{
  label: "France",
  value: 1
}, {
  label: "Allemagne",
  value: 6
}, {
  label: "Autriche",
  value: 13
}, {
  label: "Belgique",
  value: 4
}, {
  label: "Bulgarie",
  value: 28
}, {
  label: "Chypre",
  value: 18
}, {
  label: "Côte d'Ivoire",
  value: 3
}, {
  label: "Croatie",
  value: 30
}, {
  label: "Danemark",
  value: 5
}, {
  label: "Espagne",
  value: 8
}, {
  label: "Estonie",
  value: 20
}, {
  label: "Finlande",
  value: 15
}, {
  label: "France Cedex",
  value: 35
}, {
  label: "Grèce",
  value: 7
}, {
  label: "Guyane",
  value: 2
}, {
  label: "Hongrie",
  value: 23
}, {
  label: "Hors UE",
  value: -2
}, {
  label: "Irlande",
  value: 9
}, {
  label: "Italie",
  value: 10
}, {
  label: "Kenya",
  value: 34
}, {
  label: "Lettonie",
  value: 21
}, {
  label: "Lituanie",
  value: 22
}, {
  label: "Luxembourg",
  value: 11
}, {
  label: "Malte",
  value: 24
}, {
  label: "Monaco",
  value: 31
}, {
  label: "Pays-Bas",
  value: 12
}, {
  label: "Pologne",
  value: 25
}, {
  label: "Portugal",
  value: 14
}, {
  label: "République Tchèque",
  value: 19
}, {
  label: "Roumanie",
  value: 29
}, {
  label: "Royaume-Uni",
  value: 17
}, {
  label: "Slovaquie",
  value: 27
}, {
  label: "Slovénie",
  value: 26
}, {
  label: "Suède",
  value: 16
}, {
  label: "Union Européenne",
  value: -1
}]

const buildHeader = (data, service) => {
  const timestamp = new Date().getTime();
  const message = `secretKeyValue;wwwLogin;${timestamp};fr;${service};${JSON.stringify(data)}`;
  const hash = sha256(message);
  const serviceRequestHeader = `wwwLogin;${timestamp};fr;${hash}`;

  return {
    "Accept": "application/json",
    "Content-Length": JSON.stringify(data).length,
    "X-DIA-Service": serviceRequestHeader,
  }
}

export {
  DOMAIN,
  PROTOCOL,
  BASE_URL,
  TIMEOUT_LOGOUT,
  COUNTRIES,
  buildHeader
};
