import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Cart from "services/store/Cart";
import User from "services/store/User";
import { getDelivery } from 'services/api/Shop';
import { validateCoupon, confirmOrder, payCart} from 'services/api/Order';
import { useNavigate } from "react-router-dom";
import "./Payment.scss"
import CartItem from "components/CartItem/CartItem";
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";

const Payment = ({ token }) => {
    const [data, setData] = useState(null);
    const [urlSuccess, setUrlSuccess] = useState("");
    const [urlFailure, setUrlFailure] = useState("");
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const [code, setCode] = useState("");
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(0);
    const navigate = useNavigate();

    const load = async () => {
        if (refresh === 0) {
            const delivery = await getDelivery(token);
            setDeliveryInfo(delivery?.content?.addresses.find(a => a.id == Cart.delivery?.deliveryAddressId))
        }
        const products = await Cart.getCart(token);
    
        if (products?.content?.paymentCard) {
            const paymentInfo = await Cart.payCart(null, token);
            setData(paymentInfo?.content);
        }
        setLoading(false);
    }

    const applyPromoCode = async () => {
        setLoading(true);
        const coupon = await validateCoupon(code, token);
        setRefresh(refresh + 1);

        if (coupon?.content?.message) {
            toast.success(coupon?.content?.message);
        }
    }

    const confirm = async () => {
        setLoading(true);
        const result = await confirmOrder(Cart.cartId, comment, token);
        if (result?.content?.ok) {
            toast.success(<div dangerouslySetInnerHTML={{ __html: result.content.message }} />);
            setTimeout(() => {
                setLoading(false);
                navigate(`/products`);
            }, 5000);
        } else if (result == {} || result?.content?.error) {
            toast.error("Une erreur est survenue lors de la validation de la commande");
            setLoading(false);
        }

    }

    const removeCoupon = async () => {
        setCode(null);
        applyPromoCode();
    }

    const handleCommentChange = (value) => {
        setComment(value);
        Cart.comment = value;
    }

    useEffect(() => {
        setUrlSuccess(`${window.location.origin}/products?successOrder=true`);
        setUrlFailure(`${window.location.origin}/products?successOrder=false`);
        load();
    }, [refresh]);

    return (
            <div className="payment">
                <div className="sub-header"></div>
                <div className="payment-container">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <h3>Vos informations :</h3>
                            <div>
                                <div>Nom : {User.currentUser.name}</div>
                                <div>Code adhérent : {User.currentUser.code}</div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <h3>Votre commande :</h3>
                            <div>
                                <div>Date de livraison : {Cart.delivery?.deliveryDateTimeDisplay}</div>
                                <div>Transporteur : {Cart.carrierName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <h3>Adresse de livraison :</h3>
                            <div>
                                <div>{deliveryInfo?.name}</div>
                                <div>{deliveryInfo?.address1}</div>
                                {deliveryInfo?.address2 && (<div>{deliveryInfo?.address2}</div>)}
                                <div>{deliveryInfo?.zipCode} {deliveryInfo?.city}</div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <h3>Adresse de facturation :</h3>
                            <div>
                                {User?.currentUser.invoiceAddress?.name && (<div>{User?.currentUser.invoiceAddress.name}</div>)}
                                {User?.currentUser.invoiceAddress?.address1 && (<div>{User?.currentUser.invoiceAddress.address1}</div>)}
                                {User?.currentUser.invoiceAddress?.address2 && (<div>{User?.currentUser.invoiceAddress.address2}</div>)}
                                <div>{User?.currentUser.invoiceAddress.zipCode}  {User?.currentUser.invoiceAddress.city}</div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="loading">
                            <i className="fas fa-spinner fa-spin fa-lg"></i>
                        </div>
                    ) : (
                        <>
                            <h3>Récapitulatif de la commande :</h3>
                            <div className="payment-summary">
                                {Cart.cart?.length > 0 ? Cart.cart.map((product, index) => (
                                    <div className="payment-line" key={index} >
                                        <CartItem 
                                            product={product} 
                                            token={token} 
                                            onRemove={() => setLoading(true)}
                                            afterRemove={() => setLoading(false)} />
                                    </div>
                                )) : (
                                    <div>Aucun article mis au panier.</div>
                                )}
                                {Cart.couponAmount && Cart.couponName && (
                                    <div className="payment-line" key="couponLine" >
                                        <div className="cart-item">
                                            <div className="cart-itemDescription">
                                                <i class="fas fa-tags"></i>
                                                <div>
                                                    <div>{Cart.couponName}</div>
                                                    <div>{Cart.couponDescription}</div>
                                                </div>
                                            </div>
                                            <button type="button" className="cart-trash" onClick={removeCoupon}>
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="payment-promoCode">
                                <Button 
                                    style={{fontSize: '12px'}} 
                                    variant="contained" 
                                    type="submit"
                                    onClick={applyPromoCode}
                                    >
                                    Appliquer
                                </Button>
                                <TextField 
                                    id="code" 
                                    label="Code promo" 
                                    value={code}
                                    variant="outlined"
                                    size="small"
                                    onChange={e => setCode(e.target.value)} />
                            </div>
                            <div className="payment-total">
                                <div>
                                    <div className="payment-total-line"><span>Sous-Total : </span><span>{Cart.orderPrice} €</span></div>
                                    <div className="payment-total-line"><span>Transport : </span><span>{Cart.shippingPrice} €</span></div>
                                    <div>
                                        <div className="payment-total-line"><span>TVA : </span><span>{Cart.taxTotal} €</span></div>
                                        {Cart.taxes && Cart.taxes.map((taxe) => (
                                        <div className="payment-taxes"><span>{taxe.taxName} ({parseFloat(taxe.taxValue) * 100}%): </span><span>{taxe.amount}€</span></div>
                                                        ))}
                                    </div>      
                                    <div className="payment-total-line"><span>Total : </span><span>{Cart.totalPrice} €</span></div>
                                    {Cart.couponAmount && (
                                    <div className="">
                                        <span>Réduction au paiement : </span><span>{Cart.couponAmount} €</span>
                                    </div>
                                                )}
                                    <div className="payment-total-line"><span>A régler : </span><span>{Cart.toPay} €</span></div>
                                </div>
                            </div>
                            <div className="payment-comment">
                                <TextField 
                                    id="comment" 
                                    label="Commentaire (max. 90 caractères)" 
                                    value={comment}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    inputProps={{maxLength: 90}}
                                    onChange={e => handleCommentChange(e.target.value)} />
                            </div>
                            <div className="payment-buttons">
                                {Cart.paymentCard && (
                                    <form action={data?.form.action} method={data?.form.method} acceptCharset={data?.form["accept-charset"]}>
                                        {data &&
                                            Object.entries(data?.fields)
                                            .filter(f => (f[0] !== "vads_url_success" && f[0] !== "vads_url_return"))
                                            .map((field, index) => (
                                                    <input key={index} type="hidden" name={field[0]} value={field[1]} />
                                                        ))}
                                        <input type="hidden" name="vads_url_success" value={urlSuccess} />
                                        <input type="hidden" name="vads_url_return" value={urlFailure} />
                                        <Button style={{fontSize: '16px'}} className="" variant="contained" type="submit">Régler la commande par CB</Button>
                                    </form>
                                )}
                                {Cart.paymentDelayed && (
                                    <Button style={{fontSize: '16px', margin: '10px 5px 0'}} className="" variant="contained" type="button" onClick={confirm}>Valider la commande</Button>
                                )}
                            </div>
                            {Cart.cartMessage && (<div className="cart-message">{Cart.cartMessage}</div>)}
                        </>
                    )}
                    
                </div>
            </div>
            )
}

export default observer(Payment);
