import React, { useState, useEffect } from "react";
import { accountDetails, saveAccountDetails } from "services/api/Customer";
import Information from "./tabs/Information";
import Billing from "./tabs/Billing";
import Delivery from "./tabs/Delivery";
import "./MyAccount.scss"
import { toast } from "react-toastify";

const MyAccount = ({ token }) => {
  const [tab, setTab] = useState("information");
  const [dataInformation, setDataInformation] = useState(null);
  const saveAccount = async (data) => {
    const infos = data || dataInformation
    await saveAccountDetails(token, infos);
    toast.info("Informations mises à jour.")
  }
  
  useEffect(() => {
    accountDetails(token).then((data) => {
      setDataInformation(data.content);
    });

    const params = new URLSearchParams(window.location.search);

    if (params.has("livraison")) {
      setTab("livraison");
    }
    
  }, []);

  return (
    <div className="myaccount" >
      <div className="sub-header">
        <ul className="myaccount-nav">
          <li className={tab === "information" ? "active" : ""} onClick={() => setTab("information")}>Mes informations</li>
          <li className={tab === "facturation" ? "active" : ""} onClick={() => setTab("facturation")}>Facturation</li>
          <li className={tab === "livraison" ? "active" : ""} onClick={() => setTab("livraison")}> Livraison</li>
        </ul>
      </div>

      
        {tab === "information" && (
          <Information token={token} data={dataInformation} saveAccount={saveAccount} setData={setDataInformation} />
        )}
        {tab === "facturation" && (
          <Billing token={token} data={dataInformation} saveAccount={saveAccount} setData={setDataInformation} />
        )}
        {tab === "livraison" && (
          <Delivery token={token} data={dataInformation} saveAccount={saveAccount} setData={setDataInformation} />
        )}
    </div>
  )
}

export default MyAccount;
