import { getPage, getFrontpage } from 'services/api/Shop';
import home1 from '../../assets/home_1.png';
import home2 from '../../assets/home_2.png';
import home3 from '../../assets/home_3.png';
import './Home.scss';
import React, { useEffect, useState } from "react";

const Home = () => {
  const [frontLeft, setFrontLeft] = useState(""); 
  const [frontCenter, setFrontCenter] = useState(""); 
  const [frontRight, setFrontRight] = useState(""); 


  useEffect(() => {
    getPage(132).then(() => {
      const token = sessionStorage.getItem('token');
      getFrontpage(token).then((data) => {
        setFrontLeft(data?.content?.sections['front_left']?.articles[0]?.content);
        setFrontCenter(data?.content?.sections['front_center']?.articles[0]?.content);
        setFrontRight(data?.content?.sections['front_right']?.articles[0]?.content);
      })
    });
  }, []);

  return (
    <div className="container">
      <h1>Votre marché aux fleurs en ligne.</h1>
      <div className="row home-row">
        <div className="col-md-4 home-col">
          <div className="home-image">
            <img alt="trois bulles de profil" src={home1} />
          </div>
          <h2><a href="/new-account">Créez votre profil</a></h2>
          <p>Remplissez le formulaire d’inscription, nous validons votre inscription pour vous donner accès à toutes nos offres.</p>
        </div>
        <div className="col-md-4 home-col">
          <div className="home-image">
            <img alt="smartphone et femme poussant un chariot" src={home2} />
          </div>
          <h2>Choisissez vos fleurs en ligne</h2>
          <p>Choisissez parmi notre large choix de fleurs fraiches et chaque semaine nous vous proposons des colis avec nos promotions du moment.</p>
        </div>
        <div className="col-md-4 home-col">
          <div className="home-image">
            <img alt="carte de la france" src={home3} />
          </div>
          <h2>Livraisons partout en France</h2>
          <p>Nous Livrons partout en France en 48-72h selon l’heure à laquelle vous passez commande. Un suivi complet sera disponible dans votre application</p>
        </div>
      </div>
      <div className="row home-row">
        <div className="col-md-4 home-col col-left">
          <div dangerouslySetInnerHTML={{__html: frontLeft}} />
        </div>
        <div className="col-md-4 home-col col-center">
          <div dangerouslySetInnerHTML={{__html: frontCenter}} />
        </div>
        <div className="col-md-4 home-col col-right">
          <div dangerouslySetInnerHTML={{__html: frontRight}} />
        </div>
      </div>
    </div>
  )
}

export default Home;