import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { getDelivery, saveDelivery, validateDelivery } from 'services/api/Shop';
import Cart from 'services/store/Cart';
import Modal from 'react-modal';

import './Delivery.scss';
import { toast } from "react-toastify";
import productStore from "services/store/ProductStore";

const Delivery = ({ openModal, setOpenModal, countRefresh, refreshCategories }) => {
  const [deliveryItems, setDeliveryItems] = useState(null);
  const [deliveryDay, setDeliveryDay] = useState(null);
  const [deliveryDayDisplay, setDeliveryDayDisplay] = useState(null);
  const [deliveryOrderLimit, setDeliveryOrderLimit] = useState(null);
  const [deliveryAdress, setDeliveryAdress] = useState(null);
  const [carrierId, setCarrierId] = useState(null);
  const [loadingDates, setLoadingDates] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      setLoadingDates(true);
      const data = await getDelivery(token);
      updateDeliveryData(data);
      setLoadingDates(false);

      if (!Cart.delivery) {
        setOpenModal(true);
      }
    };

    fetchData();
  }, [token, countRefresh]);

  useEffect(() => {
    if (!Cart.delivery) {
      setDeliveryAdress(null);
      setCarrierId(null);
      setDeliveryDay(null)
    }
  }, [Cart.delivery]);

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const updateDeliveryData = (data) => {
    const selection = data?.content?.selection;
    const preSelectionServer = data?.content?.preSelection;
    const deliveryAddressId = parseInt(preSelectionServer?.deliveryAddressId ?? null);
    const carrierId = parseInt(preSelectionServer?.carrierId ?? null);

    setDeliveryItems(data?.content);
    setDeliveryAdress(deliveryAddressId);
    setCarrierId(carrierId);
    setDeliveryDay(preSelectionServer?.deliveryDateTime)

    if (selection?.deliveryAddressId != null && selection?.carrierId != null && selection?.deliveryDateTime) {
      Cart.delivery = selection;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const selection = {
      deliveryAddressId: deliveryAdress,
      carrierId: carrierId,
      orderDateTime: deliveryOrderLimit,
      deliveryDateTime: deliveryDay,
      deliveryDateTimeDisplay: deliveryDayDisplay
    };
    await saveDelivery(token, selection);
    await validateDelivery(token, selection);
    refreshCategories();
    Cart.delivery = selection;
    toast.info("Jour et heure de livraison mis à jour.");
    Cart.getCart(token);
    toast.info("Panier et produits mis à jour.");
    const url = new URL(window.location);
    productStore.clearProducts();

    if (url.searchParams.has("category")) {
      url.searchParams.delete("category");
      window.history.replaceState({}, "", url);
    }
    setOpenModal(false);
    setLoading(false);
  }

  const setPlanningDay = async (planning) => {
    setDeliveryOrderLimit(planning.orderDateTime);
    setDeliveryDay(planning.deliveryDateTime);
    setDeliveryDayDisplay(planning.deliveryDateTimeDisplay);
  }

  const handleCarrier = async (carrier) => {
    setCarrierId(parseInt(carrier.id));

    setLoadingDates(true);
    const selection = {
      deliveryAddressId: deliveryAdress,
      carrierId: carrier.id,
      orderDateTime: deliveryOrderLimit,
      deliveryDateTime: deliveryDay
    };
    const data = await saveDelivery(token, selection);
    updateDeliveryData(data);
    setLoadingDates(false);
  }

  const handleDelivery = async (address) => {
    setDeliveryAdress(parseInt(address.id))

    setLoadingDates(true);
    const selection = {
      deliveryAddressId: address.id,
      carrierId: carrierId,
      orderDateTime: deliveryOrderLimit,
      deliveryDateTime: deliveryDay
    };
    const data = await saveDelivery(token, selection);
    updateDeliveryData(data);
    setLoadingDates(false);
  }

  return (
    <Modal
      style={{ overlay: { overflow: 'auto' }}}
      isOpen={openModal}
      onRequestClose={onCloseModal}
      className="modal-delivery">
      <div className="container-modal">
        <form onSubmit={handleSubmit}>
          {loading ? (
            <div className="delivery-loading">
              <i className="fas fa-spinner fa-spin fa-lg"></i>
            </div>
          ) : (
            <>
              <div className="modal-deliveryContainerRow">
                <h3>Adresse de livraison</h3>
                <div className="row modal-deliveryRow">
                  {deliveryItems && (deliveryItems.addresses?.map(address => (
                    <div
                      onClick={() => handleDelivery(address)}
                      className={`col-xs-12 col-sm-4 modal-deliveryItem ${parseInt(address.id) === deliveryAdress ? "selected" : ""}`} key={address.id}>
                      <strong>{address.title}</strong>
                      <div>{address.name}</div>
                      <div>{address.address1}</div>
                      {address.address2 && (<div>{address.address2}</div>)}
                      <div>{address.zipCode} - {address.city}</div>
                      <div className="modal-deliveryRowComments" >{address.comments}</div>
                    </div>
                  )))}
                </div>
              </div>
              <div className="modal-deliveryContainerRow">
                <h3>Transporteurs</h3>
                <div className="row">
                  {deliveryItems && (deliveryItems.carriers?.map(carrier => (
                    <div onClick={() => handleCarrier(carrier)} className={`col-sm-6 col-xs-12 modal-deliveryItem ${parseInt(carrier.id) === carrierId ? "selected" : ""}`} key={carrier.id}>
                      <strong>{carrier.name}</strong>
                      <div><strong>Transport à {carrier.type?.toLowerCase()}</strong></div>
                      <pre dangerouslySetInnerHTML={{__html: carrier.shippingCost}} />
                    </div>
                  )))}
                </div>
              </div>
              <div className="modal-deliveryContainerRow">
                <h3>Jour de livraison</h3>
                <div className="modal-deliverySubtitle" >
                  <a href="/my-account?livraison=true">Vos horaires d’ouverture sont-ils correctement renseignés ?</a>  
                </div>
                  {loadingDates ? (
                    <div className="delivery-loading">
                      <i className="fas fa-spinner fa-spin fa-lg"></i>
                    </div>
                    ) : (
                      <div className="wrapper">
                        {deliveryItems && deliveryItems.deliveryPlanning && deliveryItems.deliveryPlanning?.map((planning, index) => (
                          <div onClick={() => setPlanningDay(planning)} className={`modal-deliveryItem ${planning.deliveryDateTime === deliveryDay ? "selected" : ""}`} key={index}>
                            <strong>{planning.deliveryDateTimeDisplay}</strong>
                            <div>{planning.openHours}</div>
                            <div><small>Délai de commande : {planning.orderDateTimeDisplay}</small></div>
                          </div>
                        ))}
                      </div>
                    )
                  }
              </div>
              
              <div className="modal-deliverySubmit">
                <Button variant="text" onClick={onCloseModal}>Annuler</Button>
                <Button disabled={deliveryDay === null} variant="contained" type="submit">Valider</Button>
              </div>
            </>
          )}
        </form>
      </div>
    </Modal>
  )
}

export default Delivery;