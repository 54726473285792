import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { COUNTRIES } from "services/api/Config";
import Button from '@mui/material/Button';
import DeliveryRow from "components/DeliveryRow/DeliveryRow";

const Delivery = ({ data, saveAccount, setData }) => {
  const [form, setForm] = useState([]);

  useEffect(() => {
    if (!data) return;
    setForm(data.deliveryAddresses);
  }, [data]);

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    form[index][name] = value;
    setForm([...form]);

    const newData = data;
    newData.deliveryAddresses = form;
    setData(newData);
  };

  const addAddress = () => {
    const newForm = {
      address1: "",
      address2: "",
      city: "",
      country: "",
      default: "",
      id: "",
      name: "",
      openWeek: {},
      title: "",
      zipCode: ""
    }

    setForm([...form, newForm]);
  }

  const handleSubmit = () => {
    saveAccount();
  }

  return (
    <form className="container" >
      <div className="row mb-4 mt-4 d-flex justify-content-end">
        <Button className="w-25" variant="contained" type="button" onClick={addAddress}>
          <i className="fas fa-plus-circle" style={{marginRight: '10px'}}></i>
          Ajouter
        </Button>
      </div>
      {form.length && form.map((d, index) => (
        <>
          <div className="row mb-3" key={index}>
            <div className="col-md-6">
              <p className="delivery-title">Adresse {index + 1}</p>
              <TextField 
                fullWidth 
                id="label" 
                label="Libellé" 
                variant="outlined" 
                value={d.title}
                name="title"
                onChange={(e) => handleChange(e, index)}
                margin="dense"/>
              <TextField 
                fullWidth 
                id="receiver" 
                label="Enseigne" 
                variant="outlined" 
                value={d.name}
                onChange={(e) => handleChange(e, index)}
                name="name"
                margin="dense"/>
              <TextField 
                fullWidth 
                id="address1" 
                label="Adresse" 
                variant="outlined" 
                value={d.address1}
                onChange={(e) => handleChange(e, index)}
                name="address1"
                margin="dense"/>
              <TextField 
                fullWidth 
                id="address2" 
                label="Adresse (complément)" 
                variant="outlined" 
                value={d.address2}
                onChange={(e) => handleChange(e, index)}
                name="address2"
                margin="dense"/>
              <TextField 
                fullWidth 
                id="postcode" 
                label="Code postal" 
                variant="outlined" 
                value={d.zipCode}
                onChange={(e) => handleChange(e, index)}
                name="zipCode"
                margin="dense"/>
              <TextField 
                fullWidth 
                id="city" 
                label="Ville" 
                variant="outlined" 
                value={d.city}
                onChange={(e) => handleChange(e, index)}
                name="city"
                margin="dense"/>
              <TextField
                id="country"
                select
                label="Pays"
                name="country"
                onChange={(e) => handleChange(e, index)}
                margin="dense"
                value={COUNTRIES.find(c => c.label === d.country)?.value || ""}
                fullWidth
                >
                  {COUNTRIES.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="col-md-6">
              <p>Horaires d'ouverture</p>
              <table>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <tr>
                    <td className="text-center" colSpan="2">Jour</td>
                    <td className="text-center" colSpan="3">Matin</td>
                    <td className="text-center" colSpan="3">Après-midi</td>
                  </tr>
                  <DeliveryRow data={d.openWeek[1]} day={"Lun"} index={index} form={form} setForm={setForm} />
                  <DeliveryRow data={d.openWeek[2]} day={"Mar"} index={index} form={form} setForm={setForm} />
                  <DeliveryRow data={d.openWeek[3]} day={"Mer"} index={index} form={form} setForm={setForm} />
                  <DeliveryRow data={d.openWeek[4]} day={"Jeu"} index={index} form={form} setForm={setForm} />
                  <DeliveryRow data={d.openWeek[5]} day={"Ven"} index={index} form={form} setForm={setForm} />
                  <DeliveryRow data={d.openWeek[6]} day={"Sam"} index={index} form={form} setForm={setForm} />
                  <DeliveryRow data={d.openWeek[7]} day={"Dim"} index={index} form={form} setForm={setForm} />
                </LocalizationProvider>
              </table>

            </div>
          </div>
          {index !== data.length - 1 && (
            <hr/>
          )}
        </>
      ))}
      <div className="row mb-4 mt-4 d-flex justify-content-end">
        <Button className="w-25" variant="contained" type="button" onClick={handleSubmit}>Mettre à jour</Button>
      </div>
    </form>
  )
}

export default Delivery;