import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { COUNTRIES } from "services/api/Config";
import { subscribe } from "services/api/Customer";
import Password from "components/Fields/Password";
import './Account.scss';

const Account = () => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [enseigne, setEnseigne] = useState("");
  const [siret, setSiret] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [civ, setCiv] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [validationMsg, setValidationMsg] = useState(() => []);
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    setValidationMsg([]);

    if (password.length < 6) {
      setValidationMsg([...validationMsg, 'Le mot de passe doit faire plus de 6 caractères.'])
    }

    if (password !== confirmPassword) {
      setValidationMsg([...validationMsg, 'Le mot de passe et la confirmation sont différents.'])
    }

    if (email !== confirmEmail) {
      setValidationMsg([...validationMsg, 'L\'email et la confirmation sont différents.'])
    }
  }

  const onSubscribe = async (e) => {
    e.preventDefault();

    validate();
    if (validationMsg.length !== 0) return

    const infos = {
      "credentials": {
        "login": email,
        password
      },
      "name": enseigne,
      "realName": enseigne,
      siret,
      "invoiceAddress": {
        "address1": address,
        "address2": "",
        "city": city,
        "postcode": postcode,
      },
      "deliveryAddresses": [{
        "address1": address,
        "address2": "",
        "city": city,
        "postcode": postcode,
      }],
      "contact": {
        "firstName": firstname,
        "lastName": lastname,
        phone,
        mobile,
        email,
        civ
      }
    }
    setIsLoading(true);
    const response = await subscribe(infos);
    setValidationMsg([...validationMsg, response?.content?.message]);
    setIsLoading(false);
  };

  return (
    <form className="container" onSubmit={onSubscribe}>
      <h1>Devenir Adhérent</h1>
      <p>En devenant adhérent, vous accédez à l'ensemble des offres des producteurs de la Côte d'Azur.</p>
      {validationMsg.length > 0 && (
        validationMsg.map((msg) => <p className="error">{msg}</p>)
      )}
      {isLoading ? (
        <div className="loading">
          <i className="fas fa-spinner fa-spin fa-lg"></i>
        </div>
      ) : (
        <>
          <div className="row mb-3">
          <h3>Compte</h3>
          <div className="col-md-6">
            <TextField 
              fullWidth 
              id="email" 
              label="Email" 
              variant="outlined" 
              value={email}
              margin="dense"
              required
              onChange={e => setEmail(e.target.value)}/>
            <TextField 
              fullWidth 
              id="confirmEmail" 
              label="Confirmez Email" 
              variant="outlined" 
              value={confirmEmail}
              margin="dense"
              required
              onChange={e => setConfirmEmail(e.target.value)}/>
          </div>
          <div className="col-md-6">
            <Password 
              fieldName={"password"} 
              field={password} 
              setField={setPassword} 
              label={"Mot de passe"} 
              margin={"dense"}
            />
            <Password 
              fieldName={"confirmPassword"} 
              field={confirmPassword} 
              setField={setConfirmPassword} 
              label={"Confirmez mot de passe" } 
              margin={"dense"}
            />
          </div>
        </div>
        <div className="row mb-3">
          <h3>Entreprise</h3>
          <p>Si vous êtes en phase de création (sans SIRET), rendez-vous sur la page contact.</p>
          <div className="col-md-6">
            <TextField 
              fullWidth 
              id="enseigne" 
              label="Enseigne" 
              variant="outlined" 
              value={enseigne}
              margin="dense"
              onChange={e => setEnseigne(e.target.value)}/>
            <TextField 
              fullWidth 
              id="siret" 
              label="SIRET" 
              variant="outlined" 
              value={siret}
              margin="dense"
              onChange={e => setSiret(e.target.value)}/>
            <TextField 
              fullWidth 
              id="address" 
              label="Adresse" 
              variant="outlined" 
              value={address}
              margin="dense"
              onChange={e => setAddress(e.target.value)}/>
          </div>
          <div className="col-md-6">
            <TextField 
              fullWidth 
              id="postcode" 
              label="Code postal" 
              variant="outlined" 
              value={postcode}
              margin="dense"
              onChange={e => setPostcode(e.target.value)}/>
            <TextField 
              fullWidth 
              id="city" 
              label="Ville" 
              variant="outlined" 
              value={city}
              margin="dense"
              onChange={e => setCity(e.target.value)}/>
            <TextField
              id="country"
              select
              label="Pays"
              defaultValue="1"
              margin="dense"
              fullWidth
              >
                {COUNTRIES.map((option) => (
                  <MenuItem value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
          </div>
        </div>
        <div className="row mb-3">
          <h3>Identité</h3>
          <div className="col-md-6">
            <div className="container-civility">
              <div className="w-25">
                <TextField
                  id="civility"
                  select
                  label="Civilité"
                  defaultValue=""
                  onChange={e => setCiv(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={1}>M.</MenuItem>
                  <MenuItem value={2}>Mme.</MenuItem>
                </TextField>
              </div>
              <TextField 
                fullWidth 
                id="lastname" 
                label="Nom" 
                variant="outlined" 
                value={lastname}
                onChange={e => setLastname(e.target.value)}/>
            </div>
            <TextField 
              fullWidth 
              id="firstname" 
              label="Prénom" 
              variant="outlined" 
              value={firstname}
              margin="dense"
              onChange={e => setFirstname(e.target.value)}/>
          </div>
          <div className="col-md-6">
            <TextField 
              fullWidth 
              id="phone" 
              label="Téléphone fixe" 
              variant="outlined" 
              value={phone}
              margin="dense"
              onChange={e => setPhone(e.target.value)}/>
            <TextField 
              fullWidth 
              id="mobile" 
              label="Téléphone mobile" 
              variant="outlined" 
              value={mobile}
              margin="dense"
              onChange={e => setMobile(e.target.value)}/>
          </div>
        </div>
        <div className="row">
          <FormControlLabel
            required
            value="end"
            control={<Checkbox />}
            label="Je certifie que les informations ci-dessus sont exactes"
            labelPlacement="end"
          />
        </div>
        <div className="row mb-4 mt-4 d-flex justify-content-center">
          <Button className="w-25" variant="contained" type="submit">S'inscrire</Button>
        </div>
      </>
      )}
      
    </form>
  )
}

export default Account;