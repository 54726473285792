import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { COUNTRIES } from "services/api/Config";
import Button from '@mui/material/Button';

const Billing = ({ data, saveAccount, setData }) => {
  const [country, setCountry] = useState("");
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    if (!data) return;
    setFormData(data);

    const countryValue = COUNTRIES.find(c => c.label === data.invoiceAddress.country);
    setCountry(countryValue.value);
  }, [data]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleAddressChange = (event) => {
    const { id, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      ["invoiceAddress"]: {
        ...prevState["invoiceAddress"],
        [id]: value
      }
    }));
  };

  const handleSubmit = () => {
    setData(formData);
    saveAccount(formData)
  }

  return (
    <form className="container" >
      <div className="row mb-3">
        <div className="col-md-6">
          <TextField
            id="statusName"
            select
            label="Forme juridique"
            defaultValue={""}
            value={formData.statusName}
            margin="dense"
            fullWidth
            onChange={e => handleChange(e)}
          >
            <MenuItem value={""}></MenuItem>
            <MenuItem value={"1"}>SARL</MenuItem>
            <MenuItem value={"2"}>SA</MenuItem>
            <MenuItem value={"3"}>EURL</MenuItem>
            <MenuItem value={"4"}>EARL</MenuItem>
            <MenuItem value={"5"}>SNP</MenuItem>
            <MenuItem value={"6"}>SAS</MenuItem>
            <MenuItem value={"7"}>SCEA</MenuItem>
            <MenuItem value={"8"}>EI</MenuItem>
            <MenuItem value={"9"}>Société</MenuItem>
            <MenuItem value={"10"}>Micro-Entreprise</MenuItem>
            <MenuItem value={"11"}>Autre</MenuItem>
            <MenuItem value={"12"}>Association</MenuItem>
          </TextField>
          <TextField 
            fullWidth 
            id="realName" 
            label="Raison sociale" 
            variant="outlined" 
            value={formData.realName}
            margin="dense"
            onChange={e => handleChange(e)}/>
          <TextField 
            fullWidth 
            id="siret" 
            label="Siret" 
            variant="outlined" 
            value={formData.siret}
            margin="dense"
            onChange={e => handleChange(e)}/>
          <TextField 
            fullWidth 
            id="vat" 
            label="TVA Intracom" 
            variant="outlined" 
            value={formData.vat}
            margin="dense"
            onChange={e => handleChange(e)}/>
        </div>
        <div className="col-md-6">
          <TextField 
            fullWidth 
            id="address1" 
            label="Adresse" 
            variant="outlined" 
            value={formData.invoiceAddress.address1}
            margin="dense"
            onChange={e => handleAddressChange(e)}/>
          <TextField 
            fullWidth 
            id="zipCode" 
            label="Code postal" 
            variant="outlined" 
            value={formData.invoiceAddress.zipCode}
            margin="dense"
            onChange={e => handleAddressChange(e)}/>
          <TextField 
            fullWidth 
            id="city" 
            label="Ville" 
            variant="outlined" 
            value={formData.invoiceAddress.city}
            margin="dense"
            onChange={e => handleAddressChange(e)}/>
          <TextField
            id="country"
            select
            label="Pays"
            margin="dense"
            value={country}
            onChange={e => handleAddressChange(e)}
            fullWidth
            >
              {COUNTRIES.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </div>
      <div className="row mb-4 mt-4 d-flex justify-content-end">
        <Button className="w-25" variant="contained" type="button" onClick={handleSubmit}>Mettre à jour</Button>
      </div>
    </form>
  )
}

export default Billing;