import React from "react";
import Button from '@mui/material/Button';
import Modal from 'react-modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import './OrderDetail.scss';

const OrderDetail = ({ openModal, setOpenModal, order, isLoading }) => {
  const onCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <Modal
      style={{display:'flex',alignItems:'center',justifyContent:'center', overlay: { overflow: 'auto' }}}
      isOpen={openModal}
      onRequestClose={onCloseModal}
      className="modal-orderDetail">
      <div className="container-modal">
        <h3>Détail de la commande</h3>
        {isLoading ? (
          <div className="orderDetail-loading">
            <i className="fas fa-spinner fa-spin fa-lg"></i>
          </div>
        ) : (
          <>
            <List className="orders-container" sx={{ margin: '0 20px' }}>
              {order && order.items.map(i => (
                <div key={i.id}>
                  <ListItem alignItems="flex-start">
                  <ListItemText
                      primary={`Article: ${i.fullName}`}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.primary"
                            sx={{display: 'flex', justifyContent: 'space-between'}}
                          >
                            <span>Quantité: {i.quantityDisplay}</span> 
                            <span>Prix: {i.totalPriceDisplay}</span> 
                          </Typography>

                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </div>
              ))}
            </List>
            <div className="modal-ordeDetailTotal">
              <span>Total HT : </span>
              <span>{order.orderAmount} €</span>
            </div>
            <div className="modal-ordeDetailTotal">
              <span>Frais livraison : </span>
              <span>{order.shippingAmount} €</span>
            </div>
            <div className="modal-ordeDetailTotal">
              <span>Taxes : </span>
              <span>{order.taxAmount} €</span>
            </div>
            <div className="modal-ordeDetailTotal">
              <span>Total TTC : </span>
              <span>{order.totalAmount} €</span>
            </div>
          </>
        )}
        <div className="modal-ordeDetailRow">
          <Button variant="text" onClick={onCloseModal}>Retour</Button>
        </div>
      </div>
    </Modal>
  )
}

export default OrderDetail;