import React, { useEffect, useState } from "react";
import { getCategories, getHome } from 'services/api/Shop';
import ProductStore from 'services/store/ProductStore';
import "./LeftPanel.scss"

const LeftPanel = ({token, loadProducts, width, webShopId, loadParcel, countRefresh}) => {
  const [categories1, setCategories1] = useState([]); 
  const [categories2, setCategories2] = useState([]); 
  const [categories3, setCategories3] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const [active, setActive] = useState("");

  const loadProductsFromServer = (category) => {
    if (showLeftBar) {
      setShowLeftBar(false);
    }

    ProductStore.currentPage = 1;
    const url = new URL(window.location);
    url.searchParams.set("category", category.id);
    window.history.pushState({}, "", url);
    loadProducts(category)
  }

  const activeCategory = (category) => {
    setActive(category);
  }

  const showHome = async () => {
    ProductStore.isLoading = true;
    const data = await getHome(token);
    ProductStore.title = data.content.name;
    ProductStore.categoryName = "";
    ProductStore.message = data.content.frontMessage || null;
    ProductStore.products = [];
    ProductStore.isLoading = false;
    ProductStore.isParcels = false;
    activeCategory("home");
  }

  useEffect(() => {
    const category1 = [], category2 = [], category3 = [];
    setIsLoading(true);
    showHome();

    getCategories(token).then((data) => {
      data.content.forEach(category => {
        const lvl1 = category["parent"]["parent"];
        const lvl2 = category["parent"];

        if (category.type === 'special') {
          category1.push(category);
          return;
        }

        if (!category1.some(c1 => c1.id === lvl1.id)) {
          category1.push(lvl1);
        }
        
        if (!category2.some(c2 => c2.id === lvl2.id) && lvl2.id) {
          category2.push(lvl2);
        }

        category3.push(category);
      });

      setCategories1(category1);
      setCategories2(category2);
      setCategories3(category3);
      setIsLoading(false);

      if (window.location.search) {
        const url = new URL(window.location);
        const category = url.searchParams.get("category");
        if (category) {
          const categ = data.content.find(c => c.id === category);
          loadProducts(categ)
          activeCategory(category);
        }
      }
    });
  }, [token, webShopId, countRefresh]);

  return (
    <div className={`left-panel ${showLeftBar ? '' : 'collapsed'}`} id="menu-left" style={{width: width}}>
      <button className="menu-left-mobile" onClick={() => setShowLeftBar(!showLeftBar)}>
        {showLeftBar ? (<i className="fas fa-chevron-right"></i>) : (<i className="fas fa-chevron-left"></i>)}
      </button>
      <div className={active === "home" ? "active" : ""}>
        <button 
          className="left-panelButton" 
          type="button" 
          onClick={showHome}
        >
          Accueil
        </button>
      </div>
      {isLoading ? (
          <div className="categories-loading">
            <i className="fas fa-spinner fa-spin fa-lg"></i>
          </div>
        ) : (categories1.map((category1, index) => (
          <div key={index}>
            {category1.type === 'special' ? (
              <div key={index}>
                <button className="left-panelButton" onClick={() => loadProductsFromServer(category1)}>
                  {category1.name}
                </button>
              </div>
            ) : (
            <div >
              <div className={active === category1.id ? "category-level1 active" : "category-level1"} onClick={() => activeCategory(active ? "" : category1.id)}>
                <button key={`${index}-arrow`} className="left-panelButton collapsed"
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target={`#collapse-lvl1-${index}`} 
                  aria-expanded="false" 
                  aria-controls={`collapse-lvl1-${index}`}>
                  <i className="fas fa-angle-down"></i>
                </button>
                <button 
                  key={`${index}-name`}
                  data-bs-toggle="collapse" 
                  data-bs-target={`#collapse-lvl1-${index}`} 
                  aria-expanded="false" 
                  aria-controls={`collapse-lvl1-${index}`}
                  className="left-panelButton">
                  {category1.name}
                </button>
              </div>
              <ul id={`collapse-lvl1-${index}`} className="accordion-collapse collapse" data-bs-parent="#menu-left">
                {categories2.filter(c => c.parent.id === category1.id).map((category2, index2) => (
                  <li key={`${index}-${index2}`}>
                    <div className="category-level2">
                      <button className="left-panelButton collapsed" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target={`#collapse-lvl2-${index2}`} 
                        aria-expanded="false" 
                        aria-controls={`collapse-lvl2-${index2}`}>
                        <i className="fas fa-angle-down"></i>
                      </button>
                      <button 
                        title={category2.name}
                        className="left-panelButton"
                        data-bs-toggle="collapse" 
                        data-bs-target={`#collapse-lvl2-${index2}`} 
                        aria-expanded="false" 
                        aria-controls={`collapse-lvl2-${index2}`}
                        onClick={() => category1.type === 'parcel' ? '' : loadProductsFromServer(category2)}
                      >
                        {category2.name}
                      </button>
                    </div>
                    <ul id={`collapse-lvl2-${index2}`} className="accordion-collapse collapse" data-bs-parent={`#collapse-lvl1-${index}`}>
                      {categories3.filter(c2 => c2.parent.id === category2.id).map((category3, index3) => (
                        <li key={`${index}-${index2}-${index3}`}>
                          <button title={category3.name} className="left-panelButton" onClick={() => category1.type === 'parcel' ? loadParcel(category3) : loadProductsFromServer(category3)}>
                            {category3.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          )}
          </div>
        )))
      }
    </div>
  )
}

export default LeftPanel;