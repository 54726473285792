import { makeAutoObservable } from 'mobx';
import { logout } from 'services/api/Customer';

class User {
  currentUser = {};

  constructor() {
    makeAutoObservable(this);
  }

  setUser = (data) => {
    this.currentUser = data;
    sessionStorage.setItem('user', JSON.stringify(data));
  }

  getUser = () => {
    const userSession = sessionStorage.getItem('user');

    if (Object.keys(this.currentUser).length === 0 && userSession) {
      this.currentUser = JSON.parse(userSession)
    }

    return this.currentUser;
  }

  logoutUser = () => {
    sessionStorage.setItem('user', '');
    sessionStorage.setItem('token', '');
    logout(this.currentUser.credentials?.cr?.login, sessionStorage.getItem('token'));
  }
}

const user = new User()

export default user;