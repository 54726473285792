import { makeAutoObservable } from 'mobx';
import { saveItem, getCart, deleteItem, payCart } from 'services/api/Order';
import { toast } from 'react-toastify';

class Cart {
  isLoading = false;
  delivery = null;
  cart = [];
  cartId = null;
  totalPrice = 0;
  canValidate = true;
  cartMessage = "";
  taxes = [];
  paymentCard = false;
  paymentDelayed = false;
  paymentMethod = null;
  comment = "";

  constructor() {
    makeAutoObservable(this);
  }

  payCart = async (cartId, token) => {
    return await payCart(cartId, this.comment, token);
  }

  removeItem = async (product, token) => {
    await deleteItem(product, token);
    await this.getCart(token);
    toast.info("Produits retirés!");
  }

  getCart = async (token) => {
    const products = await getCart(token)

    if (products.content?.error && products?.content?.items === null) {
      this.delivery = null;
    }

    this.cart = products?.content?.items || [];
    this.cartId = products?.content?.id;
    this.orderPrice = products?.content?.orderAmount;
    this.shippingPrice = products?.content?.shippingAmount;
    this.taxTotal = products?.content?.taxAmount;
    this.totalPrice = products?.content?.totalAmount;
    this.couponAmount = products?.content?.couponAmount;
    this.couponDescription = products?.content?.couponDescription;
    this.couponName = products?.content?.couponName;
    this.toPay = products?.content?.toPayAmount;
    this.canValidate = products?.content?.canValidate;
    this.cartMessage = products?.content?.cartMessage;
    this.paymentDelayed = products?.content?.paymentDelayed;
    const taxes = products?.content?.taxes?.taxes;
    this.taxes = [];
    this.paymentCard = products?.content?.paymentCard;
    this.paymentMethod = products?.content?.paymentMethod;
    this.invoice = products?.content?.invoice;
    this.carrierName = products?.content?.carrierName;

    if (taxes) {
      Object.entries(taxes).forEach(([_key, value]) => {
        if (value["amount"]) this.taxes.push(value);
      });
    }
    return products;
  }

  addToCart = async (product, quantity, token) => {
    //Check if product is in cart
    const productInCart = this.cart.find(c => c.product.id === product.id);

    await saveItem(productInCart?.id, product, quantity, token, this.delivery).then(products => {
      this.cart = products?.content?.items;
      this.cartId = products?.content?.id;
      this.message = products?.content?.error ? (products.content.message) : 'Produits ajoutés !';
      this.totalPrice = products?.content?.totalAmount;
      this.getCart(token);
      products?.content?.error ? toast.error(this.message) : toast.info(this.message);
      
    });
  }
}

const cart = new Cart()
export default cart;