import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo_header.png';
import Button from '@mui/material/Button';
import React from 'react';
import './Header.scss';
import Login from 'components/Modals/Login/Login';
import User from 'services/store/User';
import Search from 'components/Search/Search';

class Header extends Component {
  constructor(props){ 
    super(props) 
    this.state = {
      openModal: false
    }
  } 

  onClickConnect = e =>{
    e.preventDefault()
    this.setState({ openModal: true })
  }

  setOpenModal = (value) => {
    this.setState({ openModal: value })
  }

  logout = () => {
    this.props.setToken(null);
    localStorage.clear();
    sessionStorage.clear();
    User.logoutUser();
    window.location.reload();
  }
  
  toggleMenu = () => {
    const menu = document.getElementById('menu');

    if (menu.style.display === 'block') {
      document.getElementById('menu').style.display = 'none';
    } else {
      document.getElementById('menu').style.display = 'block';
    }
  }

  headerClass = () => {
    return this.props.token ? "header mod-noMargin" : "header";
  }

  userEmail = () => {
    const user = User.getUser();
    return user["contact"] ? user["contact"]["email"] : "email";
  }
  //                <li><button onClick={this.onClickConnect}>Se Connecter</button></li>

  render() {
    return (
      <header className={this.headerClass()}>
        <div className="header-mobile">
          <Search token={this.props.token} />
        </div>

        <div className="header-desktop">
            {this.props.token ? (
              <>
                <ul className="header-left">
                  <li><NavLink to="/"><img alt="logo dia fleur" className="header-logo" src={logo} /></NavLink></li>
                </ul>
                <Search token={this.props.token} />
              </>
            ) : (
              <ul className="header-left">
                <li><NavLink to="/"><img alt="logo dia fleur" className="header-logo" src={logo} /></NavLink></li>
                <li><NavLink to="/">Accueil</NavLink></li>
                <li><Button style={{textTransform: 'none', fontSize: '16px'}} variant="text" onClick={this.onClickConnect}>Se Connecter</Button></li>
              </ul>
            )}  
          <div>
            {this.props.token ? (
              <div className="dropdown header-right">
                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fas fa-user"></i>
                  {this.userEmail()}
                </button>
                <div className="dropdown-menu">
                  <NavLink className="dropdown-item" to="/products">Produits</NavLink>
                  <NavLink className="dropdown-item" to="/my-account">Mon compte</NavLink>
                  <NavLink className="dropdown-item" to="/orders">Mes commandes</NavLink>
                  <NavLink className="dropdown-item" to="/payment">Mon panier</NavLink>
                  <a className="dropdown-item" href="#" onClick={this.logout}>Se déconnecter</a>
                </div>
              </div>
            ) : (
              <ul className="header-right">
                <li><NavLink to="/contact">Contact</NavLink></li>
                <li><NavLink to="/new-account">Créer un compte</NavLink></li>
              </ul>
            )}
          </div>
        </div>
        <Login openModal={this.state.openModal} setOpenModal={this.setOpenModal} setToken={this.props.setToken} />

        {/* Remplacer la partie du menu mobile par */}
        <nav className="mobile-bottom-nav">
          {this.props.token ? (
            <>
              <NavLink to="/products" className="nav-item">
                <i className="fas fa-store"></i>
                <span>Produits</span>
              </NavLink>
              <NavLink to="/my-account" className="nav-item">
                <i className="fas fa-user"></i>
                <span>Compte</span>
              </NavLink>
              <NavLink to="/" className="nav-logo">
                <img src={logo} alt="logo dia fleur" />
              </NavLink>
              <NavLink to="/orders" className="nav-item">
                <i className="fas fa-shopping-bag"></i>
                <span>Commandes</span>
              </NavLink>
              <a href="#" onClick={this.logout} className="nav-item">
                <i className="fas fa-sign-out-alt"></i>
                <span>Déconnexion</span>
              </a>
            </>
          ) : (
            <>
              <NavLink to="/" className="nav-item">
                <i className="fas fa-home"></i>
                <span>Accueil</span>
              </NavLink>
              <NavLink to="/contact" className="nav-item">
                <i className="fas fa-envelope"></i>
                <span>Contact</span>
              </NavLink>
              <NavLink to="/" className="nav-logo">
                <img src={logo} alt="logo dia fleur" />
              </NavLink>
              <NavLink to="/new-account" className="nav-item">
                <i className="fas fa-user-plus"></i>
                <span>Compte</span>
              </NavLink>
              <a href="#" onClick={this.onClickConnect} className="nav-item">
                <i className="fas fa-sign-in-alt"></i>
                <span>Connexion</span>
              </a>
            </>
          )}
        </nav>
      </header>
    );
  }
}

export { Header };