import { toast } from "react-toastify";
import { DOMAIN, BASE_URL, PROTOCOL, buildHeader } from "./Config";
import * as Sentry from "@sentry/browser";

const buildData = (product, quantity, service, token = null, delivery = null, cartId = null, comment=null) => {
  const deliveryDateTime = delivery ? `${delivery?.orderDateTime}|${delivery?.deliveryDateTime}` : null;
  return {
    "session":{
      "url": BASE_URL,
      "orderDateTime": delivery?.orderDateTime,
      "token": token || ""
    },
    "content":{
      "service": service,
      "productId": product?.id,
      "quantity": quantity,
      //"product": product,
      "id": cartId || "",
      "domainName":DOMAIN,
      "token": token || "",
      "api":"order",
      "addressId": delivery?.addressId,
      "carrierId": delivery?.carrierId,
      "deliveryDateTime": deliveryDateTime,
      "customerComments": comment
    }
  };
}

const saveItem = async (cartId, product, quantity, token, delivery) => {
  try{

    const data = buildData(product, quantity, "saveItem", token, delivery, cartId);
    data.content["preSelection"] = delivery;
    data.content["selection"] = delivery;

    const response = await fetch(PROTOCOL + BASE_URL + "/api/order/saveItem", {
      method: "POST",
      headers: buildHeader(data, "saveItem"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getCart = async (token) => {
  try{

    const data = buildData(null, null, "getCart", token);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/order/getCart", {
      method: "POST",
      headers: buildHeader(data, "getCart"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const deleteItem = async (product, token) => {
  try{

    const data = buildData(product, null, "deleteItem", token, null, product.id);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/order/deleteItem", {
      method: "POST",
      headers: buildHeader(data, "deleteItem"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const payCart = async (cartId, comment, token) => {
  try{

    const data = buildData(null, null, "payCart", token, null, cartId, comment);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/order/payCart", {
      method: "POST",
      headers: buildHeader(data, "payCart"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const addParcel = async (parcelId, delivery, token) => {
  try{

      const data = buildData(null, null, "addParcel", token, delivery, parcelId);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/order/addParcel", {
      method: "POST",
      headers: buildHeader(data, "addParcel"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();

    if (jsonData.content.cartMessage) {
      toast.info(jsonData.content.cartMessage)
    }
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const validateCoupon = async (id, token) => {
  try{

    const data = buildData(null, null, "validateCoupon", token, null, id);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/order/validateCoupon", {
      method: "POST",
      headers: buildHeader(data, "validateCoupon"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const confirmOrder = async (id, comment, token) => {
  try{

    const data = buildData(null, null, "validateCart", token, null, id, comment);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/order/validateCart", {
      method: "POST",
      headers: buildHeader(data, "validateCart"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getOrder = async (token, orderId) => {
  try{

    const data = buildData(null, null, "getCart", token, null, orderId);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/order/getCart", {
      method: "POST",
      headers: buildHeader(data, "getCart"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

export {
  saveItem,
  getCart,
  deleteItem,
  payCart,
  validateCoupon,
  confirmOrder,
  getOrder,
  addParcel
};
