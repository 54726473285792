import React, { useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

const DeliveryRow = ({ data, day, index, form, setForm }) => {
  const [isEnabled, setIsEnabled] = useState(() => data !== undefined);
  const keyDay = { "Lun": 1, "Mar": 2, "Mer": 3, "Jeu": 4, "Ven": 5, "Sam": 6, "Dim": 7 };
  const defaultTime = "12:00";

  const handleChange = (value, key) => {
    const row = { 
      from1: data?.from1 || defaultTime, 
      from2: data?.from2 || defaultTime, 
      to1: data?.to1 || defaultTime, 
      to2: data?.to2  || defaultTime
    };
    row[key] = `${value.$H}:${value.$m}`;
    form[index]["openWeek"][keyDay[day]] = row;
    setForm([...form]);
    
  };

  return (
    <tr>
      <td>{day}</td>
      <td><Checkbox checked={isEnabled} onClick={() => setIsEnabled(!isEnabled)} /></td>
      <td>
        <MobileTimePicker 
          onChange={(v) => handleChange(v, "day")}
          ampm={false}
          ampmInClock={false}
          disabled={!isEnabled} 
          label="De" 
          defaultValue={data ? dayjs(`2022-04-17T${data.from1}`) : dayjs('2022-04-17T00:00')} 
          format="HH:mm" />
      </td>
      <td>-</td>
      <td>
        <MobileTimePicker 
          ampm={false}
          ampmInClock={false}
          onChange={(v) => handleChange(v, "to1")}
          disabled={!isEnabled} 
          label="A" 
          defaultValue={data ? dayjs(`2022-04-17T${data.to1}`) : dayjs('2022-04-17T00:00')} 
          format="HH:mm" />
      </td>
      <td>
        <MobileTimePicker 
          ampm={false}
          ampmInClock={false}
          onChange={(v) => handleChange(v, "from2")}
          disabled={!isEnabled} 
          label="De" 
          name="from2"
          defaultValue={data ? dayjs(`2022-04-17T${data.from2}`) : dayjs('2022-04-17T00:00')} 
          format="HH:mm" />
        </td>
      <td>-</td>
      <td>
        <MobileTimePicker 
          ampm={false}
          ampmInClock={false}
          onChange={(v) => handleChange(v, "to2")}
          disabled={!isEnabled} 
          label="A" 
          name="to2"
          defaultValue={data ? dayjs(`2022-04-17T${data.to2}`) : dayjs('2022-04-17T00:00')} 
          format="HH:mm" />
      </td>
    </tr>
  )
}

export default DeliveryRow;