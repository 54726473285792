import React, { useEffect, useRef } from "react";
import { TIMEOUT_LOGOUT } from "../../services/api/Config";

const InactivityHandler = ({ logout, setToken }) => { 
  const timeoutRef = useRef(null);

  const resetInactivityTimer = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setToken("")
      logout();
    }, TIMEOUT_LOGOUT * 1000);
  };

  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll", "click"];
    events.forEach((event) => window.addEventListener(event, resetInactivityTimer));

    resetInactivityTimer();

    return () => {
      clearTimeout(timeoutRef.current);
      events.forEach((event) => window.removeEventListener(event, resetInactivityTimer));
    };
  }, []);

  return null;
};

export default InactivityHandler;