import React from "react";
import "./Breadcrumb.scss"

const Breadcrumb = ({category, loadProducts}) => {

  return (category ? 
    <ul className="breadcrumb">
      {category.id < 0 ? (
        <li>{category.name}</li>
      ) : (
        <>
          {category.parent && category.parent.parent && (
            <>
              <li className="breadcrumb-link" onClick={() => loadProducts(category.parent.parent)}>{category.parent.parent.name}</li>
              <li className="breadcrumb-chevron"><i className="fas fa-chevron-right"></i></li>
            </>
          )}
          {category.parent && (
            <>
              <li className="breadcrumb-link" onClick={() => loadProducts(category.parent)}>{category.parent.name}</li>
              <li className="breadcrumb-chevron"><i className="fas fa-chevron-right"></i></li>
            </>
          )}
          <li>{category.name}</li>
        </>
      )}
    </ul>
    : 
    <div></div>
  )
}

export default Breadcrumb;
