import React from "react";
import Button from '@mui/material/Button';
import Modal from 'react-modal';
import './ProductDetail.scss';

const ProductDetail = ({ openModal, setOpenModal, product }) => {
  const onCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <Modal
      style={{ overlay: { overflow: 'auto' }}}
      isOpen={openModal}
      onRequestClose={onCloseModal}
      className="modal-productDetail">
      <div className="container-modal">
        <h3>{product.name}</h3>
        <p>Qualité : {product.quality}</p>
		    <p>Longueur : {product.size} cm</p>
        <p>Couleur : {product.color}</p>
        <p>Conditionnement : {product.packaging}</p>
        {product.producerName ? (<p>Producteur : {product.producerName}</p>) : ('') }
        {product.labelNames ? (<p>Remarques : {product.labelNames}</p>) : ('') }
        {product.comments ? (<p>Commentaires : {product.comments}</p>) : ('') }
        {product.country ? (<p>Origine : {product.country}</p>) : ('') }
        <p>Disponibilité : {product.availabilityMinimum}</p>
        <p>Prix : {product.sellPriceBase} € / {product.sellUnitBaseShort} soit {product.sellPriceMinimumDisplay}</p>
        
        <div className="modal-productDetailRow">
          <Button variant="text" onClick={onCloseModal}>Retour</Button>
        </div>
      </div>
    </Modal>
  )
}

export default ProductDetail;