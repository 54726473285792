import React, { useState, useEffect }  from "react";
import Cart from "services/store/Cart";
import ProductDetail from "components/Modals/ProductDetail/ProductDetail";
import ImageMagnifier from "components/ImageMagnifier/ImageMagnifier";
import Slider from "react-slick";
import ProductStore from "services/store/ProductStore";
import "./ProductCard.scss"

const ProductCard = ({ product, token, openDelivery, onClickImage }) => {
  const [quantity, setQuantity] = useState(1);
  const [openDetail, setOpenDetail] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [disablePlus, setDisablePlus] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const plus = () =>  {
    let minimumOrder = parseInt(product.minimum.replace(/[^0-9]/g, "") || 1);
    const regex = `x ${minimumOrder}`
    if (product.sellPriceBaseDisplay.includes(regex)) minimumOrder = 1

    if (minimumOrder && quantity < minimumOrder) {
      return setQuantity(minimumOrder);
    }

    setQuantity(quantity + 1);
  };
  const minus = () => {
    if (quantity === 0) return;
  
    let minimumOrder = parseInt(product.minimum.replace(/[^0-9]/g, "") || 1);
    const regex = `x ${minimumOrder}`
    if (product.sellPriceBaseDisplay.includes(regex)) minimumOrder = 1

    if (minimumOrder && quantity < minimumOrder) {
      return setQuantity(0);
    }

    setQuantity(quantity - 1);
  }
  const addToCart = async () => {
    const delivery = Cart?.delivery;
    const deliverySetted = delivery?.carrierId !== null && delivery?.deliveryAddressId !== null && delivery?.deliveryDateTime !== null && delivery?.orderDateTime !== null;

    if (!product.canAdd)
    {
        return;
    }

    if (!deliverySetted) {
      return openDelivery(true);
    }
    ProductStore.isLoading = true;
    await Cart.addToCart(product, quantity, token);
    ProductStore.isLoading = false;
    setQuantity(1);
  }

  useEffect(() => {
    const productCart = Cart.cart.find(c => c.product.id === product.id);
    const quantityToDisplay = parseInt(productCart?.quantityDisplay.replace(/[^0-9]/g, "") || 0);

    setQuantity(quantityToDisplay || 1);
    setIsInCart(quantityToDisplay > 0);
  }, [product.id]);

  useEffect(() => {
    if (quantity >= parseInt(product.availabilityMinimum.replace(/[^0-9]/g, "") || 1)) {
      setDisablePlus(true);
      return;
    }
    setDisablePlus(false);
  }, [quantity]);

  return (
    <div className="col col-sm-12 col-md-6 col-lg-4 col-xl-3" key={product.id}>
      <div className="products-card">
        {product && product.pictures?.length > 0 ?
          (product.pictures?.length > 1 ? (
            <Slider {...settings}>
              {product.pictures.map((p, index) => (
                <div key={index} className="product-imageContainer">
                  <ImageMagnifier
                    className="product-cardImageFull"
                    src={p.filename}
                    width={300}
                    height={200}
                    magnifierHeight={200}
                    magnifierWidth={200}
                    zoomLevel={2}
                    alt={product.name}
                    onClick={onClickImage}
                  />
                </div>
              ))}
            </Slider>)
            :
            <div className="product-imageContainer">
              <ImageMagnifier
                className="product-cardImageFull"
                src={product.pictures[0].filename}
                width={300}
                height={200}
                magnifierHeight={200}
                magnifierWidth={200}
                zoomLevel={2}
                alt={product.name}
                onClick={onClickImage}
              />
            </div>
          ) :
          <div className="product-cardImage"></div>
        }
        <span className="product-detail">
          <i className="fas fa-search-plus" onClick={() => setOpenDetail(true)}></i>
        </span>
        {product && product.labels?.length > 0 && product.labels.some((l) => l.code === 'france') && (
          <span className="product-labelFrance"></span>
        )}
        {product && product.labels?.length > 0 && product.labels.some((l) => l.code === 'hortisud') && (
          <span className="product-labelHortisud"></span>
        )}
        {product && product.labels?.length > 0 && product.labels.some((l) => l.code === 'fdf') && (
          <span className="product-labelFDF"></span>
        )}
        <div className="product-cardDescription">
          <div>{product.fullName}</div>
        {product.producerName ? (<div>Prod. : {product.producerName}</div>) : ('') }          
          <div>Cond. : {product.packaging}</div>
          <div>Dispo. : {product.availabilityMinimum}</div>
          <div className="d-flex justify-content-between">
            <div>Prix : {product.sellPriceBaseDisplay}</div>
          </div>
          <div className="product-cardInCart">{product.qtyInCartDisplay}</div>
          {product.canAdd && (
            <div className="product-cardContainer">
              <button 
                disabled={quantity === 0} 
                onClick={addToCart} 
                className={`products-cardCart ${quantity === 0 ? "disabled" : ""}`} 
                type="button">
                <i className="fas fa-cart-plus"></i>
                {isInCart ? "Ajouter" : "Ajouter"}
              </button>
              <div className="d-flex">
                <button onClick={minus} className="products-cardAdd" type="button">
                  <i className="fas fa-minus"></i>
                </button>
                <span>{quantity}</span>
                <button onClick={plus} className={`products-cardAdd ${disablePlus ? "disabled" : ""}`} disabled={disablePlus} type="button">
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ProductDetail openModal={openDetail} setOpenModal={setOpenDetail} product={product} />
    </div>
  )
}

export default ProductCard;
