import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.scss';

class Footer extends Component {

  render() {
    return (
      <footer className="footer">
        <ul className="footer-right">
          <li><NavLink to="/faq">FAQ</NavLink></li>
          <li><NavLink to="/cgv">CGV</NavLink></li>
        </ul>
      </footer>
    );
  }
}

export { Footer };