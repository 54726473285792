import React, { useState, useEffect } from 'react';
import { Header , Footer } from 'components';
import { Routes, Route } from 'react-router-dom';
import Modal from 'react-modal';
import Home from './Home/Home';
import Contact from './Contact/Contact';
import Account from './Account/Account';
import Products from './Products/Products';
import Orders from './Orders/Orders';
import Payment from './Payment/Payment';
import AutoLogin from './AutoLogin/AutoLogin';
import ProtectedContent from '../components/ProtectedContent/ProtectedContent';
import Cgv from './Cgv/Cgv';
import Faq from './Faq/Faq';
import MyAccount from './MyAccount/MyAccount';
import InactivityHandler from 'components/InactivityHandler/InactivityHandler';
import user from 'services/store/User';

Modal.setAppElement('#root');

const App = () => {
  const [token, setToken] = useState();

  useEffect(() => {
    const tokenString = sessionStorage.getItem('token');

    //check token validity
    if (tokenString) {
      setToken(tokenString);
    }
  }, []);

  return (
    <div className="App">
      <Header token={token} setToken={setToken} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/new-account" element={<Account />} />
        <Route path="/cgv" element={<Cgv />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/auto-login" element={<AutoLogin setToken={setToken} />} />
        <Route path="/products" element={
          <ProtectedContent token={token} setToken={setToken}>
            <Products token={token} />
          </ProtectedContent>
        }/>
        <Route path="/my-account" element={
          <ProtectedContent token={token} setToken={setToken}>
            <MyAccount token={token} />
          </ProtectedContent>
        }/>
        <Route path="/orders" element={
          <ProtectedContent token={token} setToken={setToken}>
            <Orders token={token} />
          </ProtectedContent>
        }/>
        <Route path="/payment" element={
          <ProtectedContent token={token} setToken={setToken}>
            <Payment token={token} />
          </ProtectedContent>
        }/>
      </Routes>
      <InactivityHandler logout={user.logoutUser} setToken={setToken} />
      <Footer />
    </div>
  );
}

export default App;
