import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { login as loginCall, resetPassword } from 'services/api/Customer';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Modal from 'react-modal';

import './Login.scss';
import User from "services/store/User";
import Password from "components/Fields/Password";

const Login = ({ openModal, setOpenModal, setToken }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const navigate = useNavigate();

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    const result = await loginCall(login, password);

    if (result.content && result.content.logged) {
      setToken(result.content.token);
      sessionStorage.setItem('token', result.content.token);
      User.setUser(result.content.content);
      setOpenModal(false);
      navigate("/products");
    } else if (result.message) {
      setMessage(result.message);
    }
  }

  const handleReset = async (e) => {
    e.preventDefault();
    setMessage("");
    await resetPassword(email);
    toast.success("Les instructions de réinitialisation ont été envoyées par email!");
  }

  return (
    <Modal 
      isOpen={openModal}
      onRequestClose={onCloseModal}
      className="modal-login">
      <div className="container-modal">
        {showResetPassword && (
          <>
            <h3>Réinitialisation mot de passe</h3>
            <form onSubmit={handleReset}>
              <TextField 
                fullWidth 
                id="email" 
                label="Email" 
                variant="outlined" 
                margin="normal"
                value={email}
                onChange={e => setEmail(e.target.value)}
                />
              <Button onClick={() => setShowResetPassword(false)}>Retour au login</Button>

              <div className="modal-loginSubmit">
                <Button variant="text" onClick={onCloseModal}>Retour</Button>
                <Button variant="contained" type="submit">Envoyer</Button>
              </div>
            </form>
          </>
        )}
        {!showResetPassword && (
          <>
            <h3>Se Connecter</h3>
            <form onSubmit={handleSubmit}>
              <TextField 
                fullWidth 
                id="login" 
                label="Login" 
                variant="outlined" 
                margin="normal"
                value={login}
                onChange={e => setLogin(e.target.value)}
                />
              <Password 
                fieldName={"password"} 
                field={password} 
                setField={setPassword} 
                label={"Mot de passe"} 
                margin={"normal"}
              />
              <Button onClick={() => setShowResetPassword(true)}>Mot de passe oublié?</Button>

              {message && (
                <p className="error">{message}</p>
              )}
            
              <div className="modal-loginSubmit">
                <Button variant="text" onClick={onCloseModal}>Retour</Button>
                <Button variant="contained" type="submit">Se connecter</Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  )
}

export default Login;