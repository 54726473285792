import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedContent= ({ children, token, setToken } ) => {
  const navigate = useNavigate();

  const getToken = () => {
    const tokenString = sessionStorage.getItem('token')
    return tokenString || null
  }

  useEffect(() => {
    const storage = getToken();
    if (storage) {
      setToken(storage);
      navigate(`${window.location.pathname}${window.location.search}`)
      //navigate(`/products${window.location.search}`)
    } else {
      setToken("");
      sessionStorage.setItem('token', "");
      navigate("/")
    }
  }, [token]);

  if (!token) {
    return null;
  }

  return <>{children}</>;
}

export default ProtectedContent;