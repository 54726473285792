import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login as loginCall } from "services/api/Customer";
import User from "services/store/User";

const AutoLogin = ({ setToken }) => {
  const navigate = useNavigate();

  const login = async (user, password) => {
    toast.info("Connexion automatique en cours...");
    const result = await loginCall(user, password);
    if (result.content && result.content.logged) {
      toast.success("Connexion automatique réussie.");
      setToken(result.content.token);
      sessionStorage.setItem('token', result.content.token);
      User.setUser(result.content.content);
      return navigate("/products");
    } else {
      toast.error("Echec de la connexion automatique.");
      return navigate("/");
    }
  }
  
  

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const user = urlParams.get('user');
    const password = atob(urlParams.get('pwd'));
    login(user, password);
  }, []);


}

export default AutoLogin;
