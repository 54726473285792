import React from "react";
import "./CartItem.scss"
import Cart from "services/store/Cart";

const CartItem = ({product, token, onRemove, afterRemove}) => {

    const removeItems = async () => {
        if (onRemove) {
            onRemove();
        }

        await Cart.removeItem(product, token);

        if (afterRemove) {
            afterRemove();
        }
    };

    return (
        <div className="cart-item" key={product.id}>
            <div className="cart-itemDescription">
                <img alt={product.name} className="cart-itemImage" src={product?.product?.pictures[0]?.filename} />
                <div >
                    <div className="cart-itemText">{product.fullName} - {product.sellPriceMinimumDisplay}</div>
                    <div className="cart-itemText">{product.quantityDisplay}</div>
                </div>
            </div>
            <div className="cart-itemTotalTrash">
                <div className="cart-itemTotal">{product.totalPriceDisplay}</div>
                <button type="button" className="cart-trash" onClick={removeItems}>
                    <i className="fas fa-trash-alt"></i>
                </button>
            </div>
        </div>
    )
};

export default CartItem;