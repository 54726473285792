import { makeAutoObservable } from 'mobx';
import { getProducts, getParcel, getParcels } from 'services/api/Shop';

class ProductStore {
  products = [];
  title  = "";
  categoryName = "";
  message = "";
  isParcels = false;
  isLoading = false;
  webShopTabId = 2;
  lastSearch = {};
  currentPage = 1;
  itemPerPage = 10;
  productsCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  clearProducts = () => {
    this.products = {};
    this.productsCount = 0;
  }

  refreshSearch = (token) => {
    this.searchProducts(
      token, 
      this.lastSearch["search"], 
      this.lastSearch["sizeFrom"], 
      this.lastSearch["sizeTo"],
      this.lastSearch["color"],
      this.lastSearch["categoryId"]
    );
  }

  searchProducts = (token, search, sizeFrom="", sizeTo="", color="", categoryId) => {
    this.isLoading = true;
    this.title = "";
    this.categoryName = "";
    this.message = null;
    this.isParcels = false;

    this.lastSearch = {
      search: search,
      sizeFrom: sizeFrom,
      sizeTo: sizeTo,
      color: color,
      categoryId: categoryId
    }

    const displayFrom = (this.currentPage - 1) * this.itemPerPage;

    getProducts(token, search, sizeFrom, sizeTo, color, categoryId, displayFrom, this.itemPerPage).then(data => {
      this.products = data?.content?.products;
      this.isLoading = false;
      this.productsCount = data?.content?.productsCount;
      this.categoryName = data?.content?.categoryName ? data?.content?.categoryName : "Recherche";
    })
  }

  storeParcel = (token, parcelId) => {
    this.isLoading = true;
    this.title = "";
    this.categoryName = "";
    this.message = null;
    this.isParcels = true;

    getParcels(token).then(data => {
      const parcel = data.content.find(p => p.id === parcelId);
      this.products = parcel ? [parcel] : null;
      this.isLoading = false;
    })
  }
}

const productStore = new ProductStore();

export default productStore;