import { DOMAIN, BASE_URL, PROTOCOL, buildHeader } from "./Config";
import productStore from "services/store/ProductStore";
import * as Sentry from "@sentry/browser";

const buildData = (service, pageId = 0, token = "") => {
  return {
    "session":{
      "url": BASE_URL,
      "orderDateTime": null,
      "token": token
    },
    "content":{
      "service": service,
      "id": pageId,
      "domainName": DOMAIN,
      "token": token,
      "api": "shop"
    }
  };
}

const buildDataShop = (service, token = "", categoryName = "", sizeFrom="", sizeTo="", color="", deliveryDateTime="", addressId="", carrierId= "", productId="", categoryId="") => {
  return {
    "session":{
      "url": BASE_URL,
      "orderDateTime": null,
      "token": token
    },
    "content":{
      "service": service,
      "webShopTabId": productStore.webShopTabId,
      "categoryId": categoryId,
      "name": categoryName,
      "sizeFrom": sizeFrom,
      "sizeTo": sizeTo,
      "color": color,
      "domainName": DOMAIN,
      "token": token,
      "api": "shop",
      "addressId": addressId,
      "carrierId": carrierId,
      "deliveryDateTime": deliveryDateTime,
      "productId": productId
    }
  };
}

const getContact = async () => {
  try {
    const data = buildData("shop");
    
    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/shop", {
      method: "POST",
      headers:  buildHeader(data, "shop"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getPage = async (pageId) => {
  const data = buildData("page", pageId);

  try {
    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/page", {
      method: "POST",
      headers: buildHeader(data, "page"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getFrontpage = async (token) => {
  try {
    const data = buildData("frontpage", -1, token);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/frontpage", {
      method: "POST",
      headers: buildHeader(data, "frontpage"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getPromos = async (token) => {
  try {
    const data = buildDataShop("promos", token);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/promos", {
      method: "POST",
      headers: buildHeader(data, "promos"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getHome = async (token) => {
  try {
    const data = buildDataShop("webShopTab", token);

    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/webShopTab", {
      method: "POST",
      headers: buildHeader(data, "webShopTab"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getParcel = async (token, parcelId) => {
  try {
    const data = buildData("parcelInfo", parcelId, token);
    
    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/parcelInfo", {
      method: "POST",
      headers: buildHeader(data, "parcelInfo"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getParcels = async (token) => {
  try {
    const data = buildDataShop("parcels", token);
  
    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/parcels", {
      method: "POST",
      headers: buildHeader(data, "parcels"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getCategories = async (token) => {
  try {
    const data = buildDataShop("categories", token);
    
    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/categories", {
      method: "POST",
      headers: buildHeader(data, "categories"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getProducts = async (token, categoryName, sizeFrom="", sizeTo="", color="", categoryId="", displayFrom=0, displayCount=10) => {
  try {  
    const data = buildDataShop("products", token, categoryName, sizeFrom, sizeTo, color, "","","","", categoryId);
    data.content["displayFrom"] = displayFrom;
    data.content["displayCount"] = displayCount;

    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/products", {
      method: "POST",
      headers: buildHeader(data, "products"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getTabMenu = async (token) => {
  try {
    const data = buildDataShop("menus", token);
  
    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/menus", {
      method: "POST",
      headers: buildHeader(data, "menus"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const getProductInfo = async (token, productId) => {
  try {
    const data = buildDataShop("productInfo", token, "","","","","","","", productId.split('_')[1]);
    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/productInfo", {
      method: "POST",
      headers: buildHeader(data, "productInfo"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}


const getDelivery = async (token, preSelection = null) => {
  try {
    const data = buildDataShop("selectDelivery", token);

    if (preSelection) {
      data.content["preSelection"] = preSelection;
    }

    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/selectDelivery", {
      method: "POST",
      headers: buildHeader(data, "selectDelivery"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const saveDelivery = async (token, preSelection) => {
  try {
    const deliveryDateTime = `${preSelection.orderDateTime}|${preSelection.deliveryDateTime}`;
    const data = buildDataShop("saveDelivery", token,"","","","",deliveryDateTime,preSelection.deliveryAddressId,preSelection.carrierId);
    data.content["preSelection"] = preSelection;

    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/saveDelivery", {
      method: "POST",
      headers: buildHeader(data, "saveDelivery"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

const validateDelivery = async (token, preSelection) => {
  try {
    const deliveryDateTime = `${preSelection.orderDateTime}|${preSelection.deliveryDateTime}`;
    const data = buildDataShop("validateDelivery", token,"","","","",deliveryDateTime,preSelection.deliveryAddressId,preSelection.carrierId);
    data.content["preSelection"] = preSelection;
    data.content["selection"] = preSelection;

    const response = await fetch(PROTOCOL + BASE_URL + "/api/shop/validateDelivery", {
      method: "POST",
      headers: buildHeader(data, "validateDelivery"),
      body: JSON.stringify(data)
    })
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
}

export {
  getContact,
  getPage,
  getHome,
  getPromos,
  getCategories,
  getProducts,
  getParcel,
  getParcels,
  getDelivery,
  getProductInfo,
  saveDelivery,
  validateDelivery,
  getTabMenu,
  getFrontpage
};
