import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import "./Search.scss"
import { InputAdornment } from "@mui/material";
import { useLocation } from 'react-router-dom';
import ProductStore from 'services/store/ProductStore';

const Search = ({ token }) => {
  const [search, setSearch] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [color, setColor] = useState("");
  const [hasOption, setHasOption] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setHasOption(min || max || color);
  }, [min, max, color]);

  const searchAction = (event) => {
    event.preventDefault();
    const dropdown = document.getElementById("dropdownButton");

    if (dropdown.classList.value.indexOf('show') > -1) {
      dropdown.click();
    }
    
    ProductStore.currentPage = 1;
    ProductStore.searchProducts(token, search, min, max, color);
  }

  if (location.pathname !== "/products" ) return;

  return (
    <div className="">
      <form className="search-form dropdown">
        <button type="button"  data-bs-toggle="dropdown" data-bs-auto-close="false" id="dropdownButton" aria-expanded="false">
          <i className="fas fa-bars"></i>
          {hasOption && (
            <span className="dotted"></span>
          )}
        </button>
        <TextField
          size="small"
          fullWidth 
          id="search" 
          label="Rechercher" 
          variant="outlined" 
          value={search}
          margin="dense"
          onChange={e => setSearch(e.target.value)}/>
        <button type="submit" className="glass" onClick={(e) => searchAction(e)}>
          <i className="fas fa-search"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownButton">
          <li className="search-length">
            <span>Longueur </span>
            <TextField
              size="small" 
              id="lengthMin" 
              label="Min (cm)" 
              variant="outlined" 
              value={min}
              margin="dense"
              onChange={e => setMin(e.target.value)}/>
            <TextField
              size="small" 
              id="lengthMax" 
              label="Max (cm)" 
              variant="outlined" 
              value={max}
              margin="dense"
              onChange={e => setMax(e.target.value)}/>
          </li>
          <li>
            <TextField
              id="color"
              select
              label="Couleur"
              margin="dense"
              fullWidth
              size="small"
              defaultValue={""}
              value={color}
              onChange={e => setColor(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="clear" onClick={() => setColor("")}>
                    {color && (
                      <i className="fas fa-times"></i>
                    )}
                  </InputAdornment>
                )
              }}
              >
                <MenuItem value={"3"}>Blanc</MenuItem>
                <MenuItem value={"1"}>Bleu</MenuItem>
                <MenuItem value={"45"}>Chocolat</MenuItem>
                <MenuItem value={"47"}>Crème</MenuItem>
                <MenuItem value={"95"}>Grenat</MenuItem>
                <MenuItem value={"7"}>Jaune</MenuItem>
                <MenuItem value={"34"}>Mauve</MenuItem>
                <MenuItem value={"8"}>Mix</MenuItem>
                <MenuItem value={"96"}>Noir</MenuItem>
                <MenuItem value={"6"}>Orange</MenuItem>
                <MenuItem value={"96"}>Noir</MenuItem>
                <MenuItem value={"35"}>Parme</MenuItem>
                <MenuItem value={"4"}>Rose</MenuItem>
                <MenuItem value={"2"}>Rouge</MenuItem>
                <MenuItem value={"23"}>Saumon</MenuItem>
                <MenuItem value={"9"}>Varié</MenuItem>
                <MenuItem value={"5"}>Vert</MenuItem>
            </TextField>
          </li>
        </ul>
      </form>
      
    </div>
  )
}

export default Search;
