import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { observer } from "mobx-react";
import ProductStore from 'services/store/ProductStore';
import MenuItem from '@mui/material/MenuItem';
import "./Products.scss"
import LeftPanel from "components/LeftPanel/LeftPanel";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import ProductCard from "components/ProductCard/ProductCard";
import Delivery from "components/Modals/Delivery/Delivery";
import RightPanel from "components/RightPanel/RightPanel";
import Tooltip from '@mui/material/Tooltip';
import Cart from "services/store/Cart";
import { addParcel } from "services/api/Order";
import { getTabMenu, getHome } from "services/api/Shop";
import { toast } from "react-toastify";
import productStore from "services/store/ProductStore";
import * as Sentry from "@sentry/browser";


const Products = ({token}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [rightPanelOpened, setRightPanelOpened] = useState(false);
  const [widthPanel, setWidthPanel] = useState(220);
  const [tooltip, setTooltip] = useState("");
  const [deliveryLabel, setDeliveryLabel] = useState("");
  const [webShopId, setWebShopId] = useState(ProductStore?.webShopTabId);
  const [tabMenus, setTabMenus] = useState([]);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [countRefresh, setCountRefresh] = useState(1);
  const [countRefreshCategories, setCountRefreshCategories] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [src, setSrc] = useState("");
  const [alt, setAlt] = useState("");

  const updatePage = (event, page) => {
    ProductStore.currentPage = page;
    ProductStore.refreshSearch(token);
    scrollToTop();
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const loadProducts = (category) => {
    if (!category) return
    setSelectedCategory(category);
    ProductStore.searchProducts(token, "", "", "", "", category.id);
  }

  const loadParcel = (parcel) => {
    setSelectedCategory(parcel);
    ProductStore.storeParcel(token, parcel.id);
  }

  const loadTabMenu = async () => {
    const menus = await getTabMenu(token);
    setTabMenus(menus?.content?.menus?.shop);
  }

  const setWebShop = async (shopId) => {
    ProductStore.webShopTabId = shopId;
    ProductStore.productsCount = 0;
    setCountRefresh(countRefresh + 1);
    setWebShopId(shopId);

    const url = new URL(window.location);
    url.searchParams.delete("category");
    window.history.pushState({}, "", url);
  }

  const startResize = (event) => {
    if (dragging && event.clientX >= 150) {
      setWidthPanel(event.clientX);
      sessionStorage.setItem("widthPanel", event.clientX);
    }
  };

  const addParcelToCart = async (parcelId) => {
    ProductStore.isLoading = true;
    await addParcel(parcelId, Cart.delivery, token);
    await Cart.getCart(token);
    ProductStore.isLoading = false;
    showHome();
  }

  const handleItemPerPage = (e) => {
    setItemPerPage(e.target.value);
    ProductStore.itemPerPage = e.target.value;
    ProductStore.refreshSearch(token)
  }

  const showHome = async () => {
    ProductStore.isLoading = true;
    const data = await getHome(token);
    ProductStore.title = data.content.name;
    ProductStore.categoryName = "";
    ProductStore.message = data.content.frontMessage || null;
    ProductStore.products = [];
    ProductStore.isLoading = false;
    ProductStore.isParcels = false;
  }

  useEffect(() => {
    const width = sessionStorage.getItem("widthPanel") || 220;
    Cart.getCart(token);
    loadTabMenu();
    setWidthPanel(`${width}px`);
    const params = new URLSearchParams(window.location.search);

    if (params.has("successOrder", "true")) {
      toast.success(
        "Votre commande a été passée avec succès! Vous recevrez prochainement un email.", {
          toastId: "successOrder"
        })
    }

    if (params.has("successOrder", "false")) {
      toast.error(
        "Une erreur est survenue lors du paiement, veuillez essayer à nouveau ou contactez nous.", {
          toastId: "successOrder"
        })
        Sentry.captureException("Erreur lors du paiement, cartId : " + Cart.cartId);
    }

    productStore.productsCount = 0;
  }, [token])

  useEffect(() => {
    const status = Cart.delivery && Cart.delivery.deliveryDateTimeDisplay ? `Livraison planifiée pour le ${Cart.delivery.deliveryDateTimeDisplay}` : "La livraison n'est pas définie.";
    const deliveryLabelStatus = Cart.delivery && Cart.delivery.deliveryDateTimeDisplay ? `Livraison : ${Cart.delivery.deliveryDateTimeDisplay}` : "Livraison";
    setTooltip(status);
    setDeliveryLabel(deliveryLabelStatus);
  }, [Cart.delivery])
  
  useEffect(() => {
    let divider = Math.floor(ProductStore.productsCount / itemPerPage);
    if (ProductStore.productsCount % itemPerPage !== 0) divider += 1;

    setPageCount(divider)
  }, [itemPerPage, ProductStore.productsCount])

  return (
    <div
      className="products" 
      onMouseMove={startResize}
      onMouseUp={() => setDragging(false)}
    >
      <div className="sub-header-container" key="sub-header">
        {tabMenus && tabMenus.length > 0 && tabMenus.map(tabMenu => (
          <button 
            className={ webShopId == tabMenu.link ? "sub-header-tab is-active" : "sub-header-tab"} 
            type="button" 
            onClick={() => setWebShop(tabMenu.link)}
            key={tabMenu.link}
          >
            {tabMenu.name}
          </button>
        ))}
      </div>
      <div className="sub-header">
        <Breadcrumb category={selectedCategory} loadProducts={loadProducts} />

        <ul className="sub-menu">
          <li>
            <Tooltip title={tooltip} placement="bottom">
              <button
                type="button" 
                className={Cart.delivery != null ? "completed" : ""}
                onClick={() => setOpenDelivery(true)}>
                <i className="fas fa-truck"></i>
                {deliveryLabel}
              </button>
            </Tooltip>
          </li>
          <li style={{position: "relative"}}>
            {Cart.cart?.length > 0 && (
              <span className="product-cartCount">{Cart.cart?.length}</span>
            )}
            <button type="button" onClick={() => setRightPanelOpened(!rightPanelOpened)}>
              <i className="fas fa-shopping-cart"></i>
              Panier
            </button>
          </li>
        </ul>
        <Delivery 
          openModal={openDelivery} 
          setOpenModal={setOpenDelivery} 
          countRefresh={countRefresh}
          refreshCategories={() => setCountRefreshCategories(countRefreshCategories + 1)}
        />
      </div>
      
      <div className="d-flex overflow-hidden product-panel-container">
        <LeftPanel 
          token={token} 
          loadProducts={loadProducts}
          loadParcel={loadParcel} 
          width={widthPanel} 
          webShopId={webShopId}
          countRefresh={countRefreshCategories}
        />
        <div className="product-resizer" onMouseDown={() => setDragging(true)}>
          <i className="fas fa-ellipsis-v"></i>
        </div>
        <div className="products-panel" style={{width: `calc(100vw - ${widthPanel}px)`}}>
          {ProductStore.isLoading ? (
            <div className="products-loading">
              <i className="fas fa-spinner fa-spin fa-lg"></i>
            </div>
          ) : (
            <>
              {ProductStore.isParcels ? (
                <>
                  <div className="products-container row g-4">
                    {ProductStore.products && ProductStore.products.map((product, index) => (
                      <div key={index}>
                        {product.canAdd ? (
                        <div className="products-parcelAdd">
                          <Button variant="contained" type="button" onClick={() => addParcelToCart(product.id)}>
                            Ajouter le colis au panier
                          </Button>
                        </div>) : ('') }
                        <div key={product.id} dangerouslySetInnerHTML={{__html: product.content}} />
                      </div>   
                    ))}
                    
                  </div>
                </>
              ) : (
                <>
                  <div className="products-container row g-4">
                    {ProductStore.title && (
                      <div className="col col-12">
                        <h2>{ProductStore.title}</h2>
                      </div>
                    )}
                    {ProductStore.message && (
                      <div className="col col-12">
                        <div dangerouslySetInnerHTML={{__html: ProductStore.message}} />
                      </div>
                    )}
                  </div>                  
                  <div className="products-container row">
                    {ProductStore.productsCount > 0 && ProductStore.products && (
                      <>
                        <div className="col col-6">
                            <h2>{ProductStore.categoryName}</h2>
                          <strong>
                            {ProductStore.productsCount > 1 ? 
                              `${ProductStore.productsCount} Produits trouvés` : ProductStore.productsCount === 1 ? `${ProductStore.productsCount} Produit trouvé` : ``
                            }
                          </strong>
                        </div>
                        <div className="col col-6 products-itemsPerPage">
                          <TextField
                            id="itemsPerPage"
                            select
                            label="Articles par page"
                            defaultValue={10}
                            value={ProductStore.itemPerPage}
                            margin="dense"
                            style={{minWidth: "120px"}}
                            onChange={e => handleItemPerPage(e)}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                          </TextField>
                        </div>
                        <div className="row g-4">
                          {ProductStore.products.map((product) => (
                            <ProductCard 
                              product={product} 
                              token={token} 
                              key={product.id} 
                              openDelivery={setOpenDelivery}
                              onClickImage={() => {
                                setIsOpen(true);
                                setSrc(product.pictures[0].filename);
                                setAlt(product.name);
                              }}
                              />
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                  {ProductStore.productsCount > 0 && (
                    <div className="products-pagination row g-4">
                      <Pagination 
                        count={pageCount} 
                        color="primary" 
                        style={{ display: "flex", justifyContent: "center" }}
                        onChange={updatePage}
                        page={ProductStore.currentPage}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {isOpen && (
            <div
                className="product-imageModal"
                onClick={() => setIsOpen(false)}
            >
            <img
                src={src}
                alt={alt}
                className="max-w-[90%] max-h-[90%] rounded-lg"
            />
            </div>
          )}
        </div>
        <RightPanel token={token} opened={rightPanelOpened} />
      </div>
    </div>
  )
}

export default observer(Products);